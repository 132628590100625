import { useState, useEffect, useContext } from "react";
import "./MyBookingsUpcoming.css";
import "./MyBookingsCompleted.css";
import ystar from "../../../assets/svg/Ystar.svg";
import yellowplumber from "../../../assets/svg/yellowplumber.svg";
import Rating from "../../../components/Modals/Rating";
import axios from "../../../../Common/Api/index";
import { toast } from "react-hot-toast";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { GlobalContext } from "../../../../GlobalContext";

const MyBookingsUpcoming = () => {
  const { bookingreload, setBookingreload } = useContext(GlobalContext);
  const [MyBookings, setMyBookings] = useState(true);
  const [open, setOpen] = useState({ data: null, istrue: false });
  // const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [booking, setBooking] = useState();

  const [compltebooking, setcomplteBooking] = useState();

  const userdata = JSON.parse(localStorage.getItem("login"));
  // console.log(
  //   "🚀 ~ MyBookingsUpcoming ~ userdata:",
  //   userdata.data.data.api_token
  // );
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  // console.log("🚀 ~ MyBookingsUpcoming ~ currentPage:", currentPage);
  const [Paginations, setPaginations] = useState();
  const [Paginations1, setPaginations1] = useState();

  const getBooking = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "get-booking",
        { page: currentPage },
        {
          headers: {
            Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
          },
        }
      );

      setloading(false);
      setPaginations(response?.data?.pagination);

      setBooking(response?.data?.data);
    } catch (error) {
      setloading(false);
      if (error?.response?.status === 401) {
        localStorage.removeItem("login");
        localStorage.removeItem("lat");
        localStorage.removeItem("lon");
        localStorage.removeItem("lon");
        localStorage.removeItem("long");
        localStorage.removeItem("messageSenderdata");
        navigate("/");
      }
    }
  };
  useEffect(() => {
    getBooking();
  }, [currentPage, MyBookings]);

  const CompletBooking = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "get-user-complete-booking",
        {
          page: currentPage,
        },
        {
          headers: {
            Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
          },
        }
      );

      setloading(false);
      // setPaginations(response.data.pagination);
      setcomplteBooking(response?.data?.data);
      setPaginations1(response?.data?.pagination);
    } catch (error) {
      setloading(false);
      if (error?.response?.status === 401) {
        localStorage.removeItem("login");
        localStorage.removeItem("lat");
        localStorage.removeItem("lon");
        localStorage.removeItem("lon");
        localStorage.removeItem("long");
        localStorage.removeItem("messageSenderdata");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    CompletBooking();
  }, [currentPage, MyBookings]);
  useEffect(() => {
    CompletBooking();
  }, []);
  useEffect(() => {
    if (bookingreload === true) {
      CompletBooking();
      setBookingreload(false);
    }
  }, [bookingreload]);

  const bookingdata = [
    {
      Img1: yellowplumber,
      Name1: "Jenny Wilson",
      Price1: "$ 200",
    },
    {
      Img1: yellowplumber,
      Name1: "jecob Jones",
      Price1: "$ 200",
    },
    {
      Img1: yellowplumber,
      Name1: "Dianne",
      Price1: "$ 200",
    },
    {
      Img1: yellowplumber,
      Name1: "Marvin ",
      Price1: "$ 200",
    },
  ];
  const bookingdata2 = [
    {
      Img2: yellowplumber,
      Name2: "Jenny Wilson",
      Price2: "$ 200",
    },
    {
      Img2: yellowplumber,
      Name2: "Jecob Jones",
      Price2: "$ 200",
    },
    {
      Img2: yellowplumber,
      Name2: "Dianne",
      Price2: "$ 200",
    },
    {
      Img2: yellowplumber,
      Name2: "Marvin",
      Price2: "$ 200",
    },
  ];

  const nextpage = () => {
    if (currentPage !== Paginations?.totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevpage = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };
  const nextpage1 = () => {
    if (currentPage !== Paginations?.totalPages) {
      setCurrentPage1((prev) => prev + 1);
    }
  };

  const prevpage1 = () => {
    if (currentPage !== 1) {
      setCurrentPage1((prev) => prev - 1);
    }
  };
  const prevArrow = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_51)">
        <path
          d="M7.828 11H20V13H7.828L13.192 18.364L11.778 19.778L4 12L11.778 4.22198L13.192 5.63598L7.828 11Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_51">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const nextArrow = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_51)">
        <path
          d="M16.172 13L4 13V11L16.172 11L10.808 5.63601L12.222 4.22202L20 12L12.222 19.778L10.808 18.364L16.172 13Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_51">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 -1 24 24)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  return (
    <>
      <Rating
        show={open.istrue}
        onHide={() => setOpen({ istrue: false })}
        setOpen={setOpen}
        open={open}
      />
      <div className="All-Conatinor-perfect-divv">
        <div className="All-Containor-perfect-second-divv">
          <div className="mybooking-single11-div">
            <span className="Settikgs-main-span">My Bookings</span>
          </div>
          <div className="Upcoming-Completed-Mainn mt-3">
            <div className="Upcoming-btn-Completed">
              <button
                className="Upcoming-button "
                style={{
                  backgroundColor: MyBookings ? "#F0FAF5" : "#ffffff",
                  color: MyBookings ? "#00AD5A" : "#000000",
                }}
                onClick={() => setMyBookings(true)}>
                Upcoming
              </button>
              <button
                className="completed-button"
                style={{
                  backgroundColor: MyBookings ? "#ffffff" : "#F0FAF5",
                  color: MyBookings ? "#000000" : "#00AD5A",
                }}
                onClick={() => setMyBookings(false)}>
                Completed
              </button>
            </div>
            <div className="Underline-Min-Divv mb-4"></div>
          </div>
          {MyBookings ? (
            <div className="div-to-make-card-responsive-upcoming">
              <div className="main-div-of-card23">
                {booking?.map((item1, index) => {
                  return (
                    <div className="single-card-div44" key={index}>
                      <div className="inner-div-card-content">
                        <div className="all-span-booking-time-divv">
                          <div className="Caepenetr-imgg-main mt-2">
                            <div className="imgg-text-and-upcoming-divv-ppx">
                              <div className="all-immg-car-plu-main">
                                <div className="ingg-broklyn-yellocap-divv">
                                  <img
                                    src={item1.worker_image}
                                    className="hire1-model-img"
                                    alt=""
                                  />
                                </div>
                                <div className="broklynn-star-main-divv-uux">
                                  <div className="broklyn-spann-tagg-divv">
                                    <span className="brok-spann-tagg-ppyy">
                                      {item1.worker_name}
                                    </span>
                                  </div>
                                  <div className="star-span-number-text-divv">
                                    <img src={ystar} alt="" />
                                    <span className="rating-and-star-spann">
                                      {Math.round(item1.average_rate)} (
                                      {item1.rate_count})
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="Upcoming-car-main-divv-ppx">
                                <div className="Upcoming-wandh-set-divv">
                                  <div
                                    className={`upcoming-blue-main-divv${
                                      item1.is_accepted === 1
                                        ? "accepted-card"
                                        : item1.is_accepted === 2
                                        ? "rejected-card"
                                        : "upcoming-card"
                                    }`}>
                                    <span className="span-blue-upcoming-tagg-ppx">
                                      {item1.is_accepted === 0
                                        ? "Upcoming"
                                        : item1.is_accepted === 2
                                        ? "Rejected"
                                        : "Accepted"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="Spann-doller-fixx-divv-ppx">
                              <div className="span-exp-divv">
                                <span className="clean-exp-divv">
                                  {item1.category_name}
                                </span>
                              </div>
                              <div className="doller-thirty-exp-divv">
                                <span className="doller-thirty-span">
                                  ₦ {item1.worker_price}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="date-time-main-div">
                          <div className="time-dchild-div">
                            <span className="date12-span">Date</span>
                            <span className="jun5-span">
                              {moment(item1.booking_date).format("DD MMM YYYY")}
                            </span>
                          </div>
                          <div className="time-dchild-div">
                            <span className="date12-span">Time</span>
                            <span className="jun5-span">
                              {moment(item1.start_time, "HH:mm:ss").format(
                                "h:mm"
                              )}{" "}
                              to{" "}
                              {moment(item1.end_time, "HH:mm:ss").format(
                                "h:mm A"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div
                className="d-flex align-items-center justify-content-end p-3 gap-5"
                id="pagination">
                <span className="totalpagenumber">
                  Total Page : {Paginations?.total_pages}
                </span>
                <div className="d-flex align-items-center justify-content-end p-3 gap-3">
                  <button
                    disabled={currentPage == 1 ? "disable" : ""}
                    onClick={prevpage}
                    className="fordisable">
                    {prevArrow}
                  </button>
                  <span className="pagenumber">{currentPage}</span>
                  <button
                    className="fordisable"
                    disabled={
                      currentPage == Paginations?.total_pages ? "disable" : ""
                    }
                    onClick={nextpage}>
                    {nextArrow}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="div-to-make-card-responsive-upcoming">
              <div className="main-div-of-card23">
                {compltebooking?.map((item, index) => {
                  return (
                    <div className="single-card-div44" key={index}>
                      <div className="inner-div-card-content">
                        <div className="all-span-booking-time-divv">
                          <div className="Caepenetr-imgg-main mt-2">
                            <div className="imgg-text-and-upcoming-divv-ppx">
                              <div className="all-immg-car-plu-main">
                                <div className="ingg-broklyn-yellocap-divv">
                                  <img
                                    src={item.worker_image}
                                    className="hire1-model-img"
                                    alt=""
                                  />
                                </div>
                                <div className="broklynn-star-main-divv-uux">
                                  <div className="broklyn-spann-tagg-divv">
                                    <span className="brok-spann-tagg-ppyy">
                                      {item.worker_name}
                                    </span>
                                  </div>
                                  <div className="star-span-number-text-divv">
                                    <img src={ystar} alt="" />
                                    <span className="rating-and-star-spann">
                                      {Math.round(item.average_rate)} (
                                      {item.rate_count})
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="Upcoming-car-main-divv-ppx">
                                <div className="Upcoming-wandh-set-divv">
                                  {item.is_completed === 1 ? (
                                    <div className="upcoming-green-main-divv">
                                      <span className="span-green-upcoming-tagg-ppx">
                                        Completed
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="Spann-doller-fixx-divv-ppx">
                              <div className="span-exp-divv">
                                <span className="clean-exp-divv">
                                  {item.category_name}
                                </span>
                                <span className="clean-exp-divv">
                                  {item.subcate_name}
                                </span>
                              </div>
                              <div className="doller-thirty-exp-divv">
                                <span className="doller-thirty-span">
                                  ₦ {item.worker_price}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="date-time-main-div">
                          <div className="time-dchild-div">
                            <span className="date12-span">Date</span>
                            <span className="jun5-span">
                              {moment(item.booking_date).format("DD MMM YYYY")}
                            </span>
                          </div>
                          <div className="time-dchild-div">
                            <span className="date12-span">Time</span>
                            <span className="jun5-span">
                              {moment(item.start_time, "HH:mm:ss").format(
                                "h:mm"
                              )}{" "}
                              to{" "}
                              {moment(item.end_time, "HH:mm:ss").format(
                                "h:mm A"
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="rating-btnn-divv-ppx">
                          {item.user_rate === 0 ? (
                            <button
                              className="Btnn-ratingg-service"
                              onClick={() =>
                                setOpen({ istrue: true, data: item })
                              }>
                              Rate this Service
                            </button>
                          ) : (
                            <button className="Rated_div_btn" disabled>
                              Rated
                              <span>
                                <img src={ystar} alt="" /> {item.user_rate}
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="d-flex align-items-center justify-content-end p-3 gap-5"
                id="pagination">
                <span className="totalpagenumber">
                  Total Page : {Paginations1?.total_pages}
                </span>
                <div className="d-flex align-items-center justify-content-end p-3 gap-3">
                  <button
                    disabled={currentPage == 1 ? "disable" : ""}
                    onClick={prevpage1}
                    className="fordisable">
                    {prevArrow}
                  </button>
                  <span className="pagenumber">{currentPage}</span>
                  <button
                    className="fordisable"
                    disabled={
                      currentPage == Paginations1?.total_pages ? "disable" : ""
                    }
                    onClick={nextpage1}>
                    {nextArrow}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default MyBookingsUpcoming;
