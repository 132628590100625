import React, { useContext, useState, useEffect } from "react";
import "./sec4.css";

import { useNavigate } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import required modules
import { EffectCards } from "swiper/modules";
import { GlobalContext } from "../../../../GlobalContext";

import axios from "../../../../Common/Api/index.jsx";
import { Backdrop, CircularProgress } from "@mui/material";
import sec4_1 from "../../../assets/sec4_1.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Section4 = () => {
  const { setLoginmodalshow } = useContext(GlobalContext);
  const Naviagte = useNavigate();
  const userData = JSON.parse(localStorage.getItem("login"));
  const [loading, setloading] = useState(false);
  const [Sec4data, setSec4data] = useState([]);
  // console.log("🚀 ~ Section4 ~ Sec4data:", Sec4data);
  const Lat = JSON.parse(localStorage.getItem("lat"));
  const Long = JSON.parse(localStorage.getItem("long"));
  const Bestproject = async () => {
    setloading(true);
    try {
      const Response = await axios.post("best-projects", {
        latitude: Lat,
        longitude: Long,
      });
      setloading(false);
      setSec4data(Response.data.Top_03_workers);
      // console.log("check sec4 data===>", Response.data.Top_03_workers);
    } catch (error) {}
  };
  useEffect(() => {
    Bestproject();
  }, []);
  const start = (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_35_8969)">
        <path
          d="M7.50001 10.625L3.82626 12.8687L4.82501 8.68125L1.55626 5.88125L5.84688 5.5375L7.50001 1.5625L9.15313 5.5375L13.4444 5.88125L10.175 8.68125L11.1738 12.8687L7.50001 10.625Z"
          fill="#F2B006"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_8969">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const handleclick = async (item) => {
    // console.log("🚀 ~ handledetail ~ item:", item);
    Naviagte("/WorkerProfile", {
      state: {
        item,
      },
    });
  };
  return (
    <div className="main_sec4_div">
      <div className="sub_sec4_div">
        <h2>Our Best Projects</h2>

        <div className="card_box">
          {loading
            ? Array(3)
                .fill(0)
                .map((_, index) => (
                  <div key={index}>
                    <SkeletonTheme baseColor="#949495" highlightColor="#D1D1D1">
                      <Skeleton className="card_div" />
                    </SkeletonTheme>
                  </div>
                ))
            : Sec4data.map((item, index) => {
                return (
                  <div className="card_div" key={index}>
                    {/* <img src={item.Imgs} alt="" /> */}
                    <img src={item.worker_image} alt="" />
                    <div className="card_data_sec4">
                      <div className="text_sec4_card">
                        <h4>{item.subcategory_name}</h4>
                        <div className="raiting">
                          <p>
                            {start}
                            {Math.round(item.average_rate)}
                          </p>
                          <p>( {item.rate_count} )</p>
                        </div>
                      </div>
                      <button
                        // onClick={() =>
                        //   userData ? handleclick(item) : setLoginmodalshow(true)
                        // }
                        onClick={() => handleclick(item)}
                        className="btn4">
                        Book Now
                      </button>
                    </div>
                  </div>
                );
              })}
        </div>

        <div className="ccard_box">
          <Swiper effect={"cards"} modules={[EffectCards]}>
            {loading
              ? Array(0)
                  .fill(0)
                  .map((_, index) => (
                    <div key={index}>
                      <SkeletonTheme
                        baseColor="#949495"
                        highlightColor="#D1D1D1">
                        <Skeleton className="card_div" />
                      </SkeletonTheme>
                    </div>
                  ))
              : Sec4data.map((item, index) => {
                  // console.log("🚀 ~ {sec4data.map ~ item:=====----====>", sec4data);
                  return (
                    <SwiperSlide key={index}>
                      <div className="main_swiper_box">
                        {/* <img src={item.Imgs} alt="" /> */}
                        <img src={item.worker_image} alt="" />
                        <div className="card_data_sec4">
                          <div className="text_sec4_card">
                            <h4>{item.subcategory_name}</h4>
                            {/* <h4>arbaz</h4> */}
                            <div className="raiting">
                              <p>
                                {start}
                                {item.avg_rate}
                              </p>
                              <p>( {item.rate_count} )</p>
                            </div>
                          </div>
                          <button
                            // onClick={() =>
                            //   userData
                            //     ? handleclick(item)
                            //     : setLoginmodalshow(true)
                            // }
                            onClick={() => handleclick(item)}
                            className="btn4">
                            Book Now
                          </button>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Section4;
