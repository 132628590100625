import { useEffect, useState } from "react";
import "./Favorites.css";
import yellowplumber from "../../../assets/yellowplumber.png";
import blackplumber from "../../../assets/Blackplumber.png";
import star from "../../../assets/star.png";
import axios from "../../../../Common/Api/index";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const Favorites = () => {
  const navigate = useNavigate();
  const [Favoritess, setFavoritess] = useState([]);
  // console.log("🚀 ~ file: Favorites.jsx:13 ~ Favoritess:", Favoritess);
  const [loading, setloading] = useState(false);
  const userdata = JSON.parse(localStorage.getItem("login"));
  const getFavorites = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "get-fav",
        { page: 1 },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
          },
        }
      );
      setloading(false);
      setFavoritess(response.data.favoriteList.data);
      if (response.data.favoriteList == null) {
        navigate("/");
      }

      // console.log(response, "check out the response");
    } catch (error) {
      // console.log("check error get-fev", error);
      setloading(false);
      if (error?.response?.status === 401) {
        localStorage.removeItem("login");
        localStorage.removeItem("lat");
        localStorage.removeItem("lon");
        localStorage.removeItem("lon");
        localStorage.removeItem("long");
        localStorage.removeItem("messageSenderdata");
        navigate("/");
      }
    }
  };
  useEffect(() => {
    getFavorites();
  }, []);
  // useEffect(() => {
  //   getFavorites();
  // }, [Favoritess]);

  const removeFavorites = async (id) => {
    setloading(true);
    try {
      const removeFavoritesRequest = await axios.post(
        "remove-fav",
        { id: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
          },
        }
      );

      // console.log(
      //   "🚀 ~ file: Favorites.jsx:49 ~ removeFavorites ~ removeFavoritesRequest:",
      //   removeFavoritesRequest
      // );
      if (removeFavoritesRequest.data.status === 1) {
        toast.success(removeFavoritesRequest.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }

      getFavorites();
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  const addata = [
    {
      img: yellowplumber,
      price: "$ 30",
      Name: "Brooklyn Simmons",
      paragraph: "Phasellus aliquet erat eu magna aliquam, eget suscipit...",
    },
    {
      img: blackplumber,
      price: "$ 50",
      Name: "Marvin McKinney",
      paragraph: "Phasellus aliquet erat eu magna aliquam, eget suscipit...",
    },
    {
      img: blackplumber,
      price: "$ 50",
      Name: "Marvin McKinney",
      paragraph: "Phasellus aliquet erat eu magna aliquam, eget suscipit...",
    },
    {
      img: blackplumber,
      price: "$ 50",
      Name: "Marvin McKinney",
      paragraph: "Phasellus aliquet erat eu magna aliquam, eget suscipit...",
    },
    {
      img: blackplumber,
      price: "$ 50",
      Name: "Marvin McKinney",
      paragraph: "Phasellus aliquet erat eu magna aliquam, eget suscipit...",
    },
    {
      img: blackplumber,
      price: "$ 50",
      Name: "Marvin McKinney",
      paragraph: "Phasellus aliquet erat eu magna aliquam, eget suscipit...",
    },
    {
      img: blackplumber,
      price: "$ 50",
      Name: "Marvin McKinney",
      paragraph: "Phasellus aliquet erat eu magna aliquam, eget suscipit...",
    },
    {
      img: blackplumber,
      price: "$ 50",
      Name: "Marvin McKinney",
      paragraph: "Phasellus aliquet erat eu magna aliquam, eget suscipit...",
    },
  ];
  return (
    <>
      <div className="All-Conatinor-perfect-divv">
        <div className="All-Containor-perfect-second-divv">
          <div className="recent-search-sec-div">
            <div className="edit-profile-single-div">
              <span className="Settikgs-main-span">Favourites</span>
            </div>
            <div className="card-sec-main-div mt-4">
              {/* {addata.map((item, index) => { */}
              {Favoritess?.map((item, index) => {
                return (
                  <div
                    className="card-inr-single-div"
                    key={index}
                    onClick={() => navigate("/WorkerProfile")}>
                    <div
                      className="svg-image-div"
                      onClick={(event) => {
                        event.stopPropagation();
                        removeFavorites(item.id);
                      }}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_38_1771)">
                          <path
                            d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3Z"
                            fill="#00AD5A"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_38_1771">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div>
                      <img
                        src={item.worker_image}
                        alt=""
                        className="item-image-card"
                      />
                    </div>
                    <span className="item-span-card">{item.worker_name}</span>
                    {/* <div className="image-review-dv">
                      <img src={star} alt="" className="star-img-90" />
                      <span className="image-review-span">5.0</span>
                      <span className="image-review-span">(233)</span>
                    </div> */}
                    <div className="item-dono-div">
                      <div className="image-review-dv">
                        <img src={star} alt="" className="star-img-90" />
                        <span className="image-review-span">
                          {item.average_rate == null
                            ? 0
                            : Math.round(item.average_rate)}
                        </span>
                        <span className="image-review-span">
                          ({item.rate_count})
                        </span>
                      </div>
                      <div className="doller-thirty-exp-divv">
                        <span className="doller-thirty-span">
                          ₦ {item.worker_price}
                        </span>
                      </div>
                    </div>

                    {/* <div className="item-clear-div">
                      <div className="span-clem-exp-divv">
                        <span className="clean-exp-divv">
                          {item.category_name}
                        </span>
                      </div>
                    </div> */}

                    {/* <div className="doller-thirty-exp-divv">
                        <span className="doller-thirty-span">
                         ₦{item.worker_price}
                        </span>
                      </div> */}
                    <div className="item-dono-div" style={{ width: "100%" }}>
                      <div className="span-clem-exp-divv">
                        <span
                          className="clean-exp-divv"
                          style={{ fontSize: "13px" }}>
                          {item?.category_name}
                        </span>
                      </div>
                    </div>
                    <div className="item-dono-div" style={{ width: "100%" }}>
                      <div className="span-clem-exp-divv">
                        <span
                          className="clean-exp-divv_sub"
                          style={{ fontSize: "13px" }}>
                          {item?.subcategory_name}
                        </span>
                      </div>
                    </div>

                    <span className="item-para-span">{item.paragraph}</span>
                    <div className="book-btnn-divv">
                      <button
                        className="book-btnn-spann"
                        onClick={(event) => {
                          event.stopPropagation();
                          navigate("/BookProject");
                        }}>
                        Book Now
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Favorites;
