import { useState, useEffect, useContext } from "react";
import "../Search/Search.css";
import yellowplumber from "../../assets/yellowplumber.png";
import blackplumber from "../../assets/Blackplumber.png";
import star from "../../assets/star.png";
import Navbar2 from "../UI/Nvabar2";
import Footer from "../UI/Footer";
import { useNavigate } from "react-router-dom";
import axios from "../../../Common/Api/index";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-hot-toast";
import { GlobalContext } from "../../../GlobalContext";

const Search = () => {
  const { setLoginmodalshow } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [category, setcategory] = useState([]);

  const [Subcatname, setSubcatname] = useState([]);

  const [search, setsearch] = useState([]);
  // console.log("🚀 ~ Search ~ search:", search);
  const [selectID, setselectID] = useState("");
  const [keyword, setkayword] = useState("");
  const [Rateparhour, setRateparhour] = useState("");
  const [selectedSubcatId, setSelectedSubcatId] = useState("");
  const [selectedCatId, setSelectedCatId] = useState("");
  const [selectedRating, setSelectedRating] = useState("");

  const [Paginations, setPaginations] = useState();
  console.log("🚀 ~ Search ~ Paginations:", Paginations);
  const [currentPage, setCurrentPage] = useState(1);
  const [recentSerch, setrecentSerch] = useState([]);
  console.log("🚀 ~ Search ~ recentSerch:", recentSerch);

  const worker = [
    {
      name: "Plumber",
    },
    {
      name: "Electrician",
    },
    {
      name: "House Cleaning",
    },
    {
      name: "Home Repair",
    },
    {
      name: "Plumber",
    },
    {
      name: "Electrician",
    },
    {
      name: "House Cleaning",
    },
    {
      name: "Home Repair",
    },
    {
      name: "Electrician",
    },
    {
      name: "House Cleaning",
    },
    {
      name: "Home Repair",
    },
  ];

  const userdata = JSON.parse(localStorage.getItem("login"));
  // console.log("🚀 ~ Search ~ userdata:", userdata.data.data.id);
  const addfavorites = async (id) => {
    setloading(true);
    // console.log(id, "check your id ");
    try {
      const response = await axios.post(
        "/add-fav",
        {
          worker_id: id,
          is_like: 1,
        },
        {
          headers: {
            "content-Type": "application/json",
            Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
          },
        }
      );
      // console.log(response, "addfavorite------");
      setloading(false);
      if (response.data.status == "success") {
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
        if (userdata.data.data?.user_type === 2) {
          Searchapi(selectID);
        }
        // navigate("/Profile/Favorites")
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const getcategory = async () => {
    try {
      const response = await axios.get("get-category", {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // console.log("category list", response.data.data);
      setcategory(response.data.data);
      // if (response.data.data.length > []) {
      //   setSubcatname(response.data.data[""].Subcategory); // Save the subcategories of the first category
      // }
      const allSubcategories = response.data.data.reduce(
        (acc, currentCategory) => {
          return acc.concat(currentCategory.Subcategory);
        },
        []
      );

      // Save all subcategories to state
      setSubcatname(allSubcategories);
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    getcategory();
  }, []);

  const nextpage = () => {
    if (currentPage !== Paginations?.totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const prevpage = () => {
    if (currentPage !== 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };
  const prevArrow = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_51)">
        <path
          d="M7.828 11H20V13H7.828L13.192 18.364L11.778 19.778L4 12L11.778 4.22198L13.192 5.63598L7.828 11Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_51">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const nextArrow = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_51)">
        <path
          d="M16.172 13L4 13V11L16.172 11L10.808 5.63601L12.222 4.22202L20 12L12.222 19.778L10.808 18.364L16.172 13Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_51">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 -1 24 24)"
          />
        </clipPath>
      </defs>
    </svg>
  );
  const userid = userdata?.data?.data.id;
  // console.log("🚀 ~ Search ~ userid:", userid);

  const Searchapi = async (searchText = keyword) => {
    // console.log(e.target.value, "checking category id show====");
    try {
      setloading(true);
      const response = await axios.post(
        "search-category",
        {
          name: searchText,
          ratePerhour: Rateparhour,
          rating: selectedRating,
          cat_id: selectedCatId,
          subcate_id: selectedSubcatId,
          page: currentPage,
          user_id: userdata ? userid : "",
        },
        {
          headers: {
            "content-Type": "application/json",
            Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
          },
        }
      );
      setloading(false);
      setsearch(response.data.service);
      setPaginations(response.data.pagination);
      if (response.data.pagination.total_pages < 2) {
        setCurrentPage(1);
      }
      // console.log("checking search results===>", response);
    } catch (error) {
      setloading(false);
    }
  };
  useEffect(() => {
    if (currentPage > 1) {
      Searchapi();
    }
  }, [currentPage]);

  // useEffect(() => {
  //   if (keyword.length > 2 && selectID) {
  //     Searchapi();
  //   } else if (!keyword.length && selectID) {
  //     Searchapi();
  //   }
  // }, [selectID, keyword]);

  const [filteredSubcategories, setFilteredSubcategories] = useState([]);

  const [subcategories, setSubcategories] = useState([]);

  const handleCategoryChange = (e) => {
    const catId = e.target.value;
    setSelectedCatId(catId);

    const selectedCategory = category.find(
      (cat) => cat.CatId.toString() === catId
    );
    if (selectedCategory) {
      setSubcategories(selectedCategory.Subcategory);
    } else {
      setSubcategories([]);
    }
  };
  const handleSubcategoryChange = (e) => {
    setSelectedSubcatId(e.target.value);
  };

  const handledetail = async (item) => {
    // console.log("🚀 ~ handledetail ~ item:", item);
    navigate("/WorkerProfile", {
      state: {
        item,
      },
    });
  };
  const handlechangerating = (e) => {
    setSelectedRating(e.target.value);
  };

  const recentSearch = async () => {
    try {
      const response = await axios.get("history", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
        },
      });
      // console.log("🚀 ~ recentSearch ~ response:", response.data.search);
      setrecentSerch(response?.data?.search);
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    recentSearch();
  }, [search]);
  const handleRecentSearchClick = (searchText) => {
    console.log("🚀 ~ handleRecentSearchClick ~ searchText:", searchText);
    setkayword(searchText);
    // Searchapi(searchText);
  };
  return (
    <>
      <div className="Search-main-divv">
        <div className="Search-section-divv">
          <div className="main_input_div_of_search">
            <div className="srch-main-box">
              <div className="svg-p-div" style={{ width: "250px" }}>
                <div>
                  <svg
                    className="svg-margin"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_20_1236)">
                      <path
                        d="M18.031 16.617L22.314 20.899L20.899 22.314L16.617 18.031C15.0237 19.3082 13.042 20.0029 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20.0029 13.042 19.3082 15.0237 18.031 16.617ZM16.025 15.875C17.2941 14.5699 18.0029 12.8204 18 11C18 7.132 14.867 4 11 4C7.132 4 4 7.132 4 11C4 14.867 7.132 18 11 18C12.8204 18.0029 14.5699 17.2941 15.875 16.025L16.025 15.875Z"
                        fill="#211B24"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_20_1236">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <input
                  className="i-t-a-a"
                  type="search"
                  id="gsearch"
                  name="gsearch"
                  placeholder="Search"
                  maxLength={10}
                  value={keyword}
                  onChange={(e) => setkayword(e.target.value)}
                />
              </div>
              <div className="svg-p-div" style={{ width: "250px" }}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_6_745)">
                    <path
                      d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H18C18 20.4087 17.3679 18.8826 16.2426 17.7574C15.1174 16.6321 13.5913 16 12 16C10.4087 16 8.88258 16.6321 7.75736 17.7574C6.63214 18.8826 6 20.4087 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
                      fill="#14161B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6_745">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <select
                  className="selact-tag-one"
                  style={{ width: "80%" }}
                  name="YourSpeciality"
                  value={selectedCatId}
                  onChange={handleCategoryChange}
                  // onChange={(e) => setselectID(e.target.value)}
                  placeholder="Select Service">
                  <option value="" disabled selected hidden>
                    Select Service
                  </option>
                  {category.map((item, index) => {
                    return (
                      <option key={index} value={item.CatId}>
                        {item.CatName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="svg-p-div" style={{ width: "250px" }}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_6_745)">
                    <path
                      d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H18C18 20.4087 17.3679 18.8826 16.2426 17.7574C15.1174 16.6321 13.5913 16 12 16C10.4087 16 8.88258 16.6321 7.75736 17.7574C6.63214 18.8826 6 20.4087 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
                      fill="#14161B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_6_745">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <select
                  className="selact-tag-one"
                  style={{ width: "80%" }}
                  name="YourSpeciality"
                  value={selectedSubcatId}
                  onChange={handleSubcategoryChange}
                  placeholder="Select Service">
                  <option value="" disabled selected hidden>
                    Select Sub Service
                  </option>
                  {subcategories.map((subcat) => (
                    <option key={subcat.SubcateId} value={subcat.SubcateId}>
                      {subcat.SubcateName}
                    </option>
                  ))}
                  {/* {Subcatname.map((item, index) => {
                  return (
                    <option key={index} value={item.SubcateId}>
                      {item.SubcateName}
                    </option>
                  );
                })} */}
                </select>
              </div>
              <div className="svg-p-div" style={{ width: "110px" }}>
                <img src={star} alt="" />
                <select
                  className="selact-tag-one"
                  style={{ width: "100px" }}
                  name="rating"
                  placeholder="Rating"
                  value={selectedRating}
                  onChange={handlechangerating}>
                  <option value="">Rate</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="svg-p-div" style={{ width: "100px" }}>
                <input
                  type="number"
                  id="gsearch"
                  name="gsearch"
                  placeholder="Price"
                  onChange={(e) => {
                    setRateparhour(e.target.value);
                  }}
                  style={{ width: "100%", height: "100%", outline: "none" }}
                />
              </div>
              <div className="serc-btnn-main-divv">
                <button className="srch-btn" onClick={() => Searchapi(keyword)}>
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="Recent-srch-main-div">
            <div className="recent-srch-sec-divv">
              {userdata ? (
                <>
                  <div className="recent-heading-divv">
                    <span className="recent-heading-spann-tagg">
                      Recent Searches
                    </span>
                  </div>
                  <div className="plumber-srch-divv">
                    {recentSerch?.map((item, index) => {
                      const textLimit = 10;
                      const truncatedText = (text) => {
                        return text.length > textLimit
                          ? text.substring(0, textLimit) + "..."
                          : text;
                      };
                      return (
                        <div
                          className="plumber-single-text-boxx"
                          key={index}
                          onClick={() =>
                            handleRecentSearchClick(item.recent_text)
                          }>
                          <span className="carpenter-header-divv">
                            {truncatedText(item.recent_text)}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : null}
              <div className="recent-heading-divv">
                <span className="recent-heading-spann-tagg">
                  Search Results
                </span>
              </div>
              {search.length === 0 ? (
                <span className="noDATA_text">No Data Found</span>
              ) : (
                <div className="card-section-main-div">
                  {search.map((item, index) => {
                    const textLimit = 20;
                    const discriptionText = (text) => {
                      return text.length > textLimit
                        ? text.substring(0, textLimit) + "..."
                        : text;
                    };
                    return (
                      <div
                        className="card-ineer-single-div"
                        key={index}
                        onClick={() => handledetail(item)}>
                        <div
                          className="svg-image-div"
                          onClick={(event) => {
                            event.stopPropagation();

                            if (userdata) {
                              if (item?.is_like === 1) {
                                toast.error(
                                  "Please unfavorite this worker from your favorites list.",
                                  {
                                    position: "top-right",
                                    theme: "colored",
                                  }
                                );
                              } else {
                                addfavorites(item.id);
                              }
                            } else {
                              setLoginmodalshow(true);
                            }
                            // addfavorites(item.id);
                          }}>
                          {item.is_like === 1 ? (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_38_1771)">
                                <path
                                  d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3Z"
                                  fill="#00AD5A"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_38_1771">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          ) : (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_6_799)">
                                <path
                                  d="M16.5 3C19.538 3 22 5.5 22 9C22 16 14.5 20 12 21.5C9.5 20 2 16 2 9C2 5.5 4.5 3 7.5 3C9.36 3 11 4 12 5C13 4 14.64 3 16.5 3ZM12.934 18.604C13.815 18.048 14.61 17.495 15.354 16.903C18.335 14.533 20 11.943 20 9C20 6.64 18.463 5 16.5 5C15.424 5 14.26 5.57 13.414 6.414L12 7.828L10.586 6.414C9.74 5.57 8.576 5 7.5 5C5.56 5 4 6.656 4 9C4 11.944 5.666 14.533 8.645 16.903C9.39 17.495 10.185 18.048 11.066 18.603C11.365 18.792 11.661 18.973 12 19.175C12.339 18.973 12.635 18.792 12.934 18.604Z"
                                  fill="#00AD5A"
                                  // fill="#14161B"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_6_799">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </div>
                        <div onClick={() => navigate("/WorkerProfile")}>
                          <img
                            src={item.image}
                            alt=""
                            className="item-image-card"
                          />
                        </div>
                        <span className="item-span-card">{item.user_name}</span>
                        <div className="item-dono-div">
                          <div className="image-review-dv">
                            <img src={star} alt="" className="star-img-90" />
                            <span className="image-review-span">
                              {item?.average_rate
                                ? Math.round(item?.average_rate)
                                : "0"}
                            </span>
                            <span className="image-review-span">
                              ({item.rate_count})
                            </span>
                          </div>
                          <div className="doller-thirty-exp-divv">
                            <span className="doller-thirty-span">
                              ₦ {item.price}
                            </span>
                          </div>
                        </div>

                        <div className="category_name_div">
                          <div className="span-clem-exp-divv">
                            <span className="clean-exp-divv">
                              {item.category_name}
                            </span>
                          </div>
                          <div className="span-clem-exp-divv">
                            <span className="clean-exp-divv">
                              {item.subcategory_name}
                            </span>
                          </div>
                        </div>
                        <div className="description_div">
                          <span className="item-paragraph-span">
                            {/* {item.description} */}
                            {discriptionText(item.description)}
                            {/* {trimDescription(
                              "lorem ipsum dolor sit amet, consectetur adip"
                            )} */}
                          </span>
                        </div>

                        <div className="book-btnn-divv">
                          <button
                            className="book-button-spann"
                            onClick={(event) => {
                              event.stopPropagation();
                              handledetail(item);
                            }}>
                            Book Now
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {Paginations?.total_pages ? (
            <div className="d-flex align-items-center justify-content-end p-3 gap-5">
              <span className="totalpagenumber">
                Total Page : {Paginations?.total_pages}
              </span>
              <div className="d-flex align-items-center justify-content-end p-3 gap-3">
                <button
                  disabled={currentPage == 0 ? "disable" : ""}
                  onClick={prevpage}
                  className="fordisable">
                  {prevArrow}
                </button>
                <span className="pagenumber">{currentPage}</span>
                {/* <span className="pagenumber">{Paginations?.current_page}</span> */}
                <button
                  className="fordisable"
                  disabled={
                    currentPage == Paginations?.total_pages ? "disable" : ""
                  }
                  onClick={nextpage}>
                  {nextArrow}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* {!loading && <div></div>} */}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Search;
