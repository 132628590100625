import { useState, useEffect, useContext } from "react";
import "./Dashboard.css";
import Navbar2 from "../../components/UI/Navbar_SP2";
import Footer from "../../../User/components/UI/Footer";
import new_card_img from "../../assets/new_card.png";
import new_data from "./new_data.js";
import DetailsModals from "../../components/Modals/ViewFullDetail";
import Chatbox from "../../../User/components/Modals/Chabox";
import axios from "../../../Common/Api/index";
import { toast } from "react-hot-toast";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { GlobalContext } from "../../../GlobalContext";
import { generateChatId } from "../../components/generateChatId";

const Dashboard = () => {
  // const [MyDashboard, setMyDashbord] = useState(0);
  const [open, setOpen] = useState({ data: null, istrue: false });
  const [chatbox, setChatbox] = useState(false);
  const [loading, setloading] = useState(false);
  const userdata = JSON.parse(localStorage.getItem("login"));
  const [servicebooking, setServicebooking] = useState();
  // console.log("🚀 ~ servicebooking:", servicebooking);
  const [upComingbookingdata, setupComingbookingdata] = useState();

  const { setSendotpnumber, MyDashboard, setMyDashbord, setModalofAvaliable } =
    useContext(GlobalContext);
  const [CompletBookingdata, setCompletBookingdata] = useState();

  const [selectedId, setSelectedId] = useState(null);
  // console.log("🚀 ~ selectedId:", selectedId);

  const navigate = useNavigate();

  const getBooking = async () => {
    setloading(true);
    try {
      const response = await axios.get("get-worker-booking", {
        headers: {
          Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
        },
      });
      setloading(false);

      setServicebooking(response.data.data);
    } catch (error) {
      setloading(false);
      // console.log(error);
      if (error?.response?.status == 401) {
        localStorage.removeItem("login");
        navigate("/");
        // toast.error(error.message, {
        //   position: "top-left",
        //   theme: "colored",
        // });
      }
    }
  };
  useEffect(() => {
    getBooking();
  }, []);

  useEffect(() => {
    getBooking();
  }, [MyDashboard]);

  const Acceptreject = async (id, is_accepted) => {
    setloading(true);
    // console.log(id);
    // console.log(is_accepted);
    try {
      const response = await axios.post(
        "accept_reject",
        {
          id: id,
          is_accepted: is_accepted,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userdata.data.data.api_token}`,
          },
        }
      );
      console.log("🚀 ~ Acceptreject ~ response:", response);

      setloading(false);

      if (response?.data?.message === "Accepted") {
        toast.success(response?.data?.message, {
          position: "top-right",
          theme: "colored",
        });
        setMyDashbord(1);
        getBooking();
      } else if (response.data.message == "Rejected") {
        toast.success(response?.data?.message, {
          position: "top-right",
          theme: "colored",
        });
        getBooking();
      }
    } catch (error) {
      console.log("🚀 ~ Acceptreject ~ error:", error);
      setloading(false);
      toast.error(error.response.data.message, {
        position: "top-left",
        theme: "colored",
      });
    }
  };

  const chat_ico = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_25_1993)">
        <path
          d="M7.29101 20.824L2.00001 22L3.17601 16.709C2.40154 15.2604 1.99754 13.6426 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.3574 22.0025 8.73963 21.5985 7.29101 20.824ZM7.58101 18.711L8.23401 19.061C9.39256 19.6801 10.6864 20.0027 12 20C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4004 4.00346 8.93854 4.60896C7.47674 5.21447 6.22731 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 13.334 4.32501 14.618 4.94001 15.766L5.28901 16.419L4.63401 19.366L7.58101 18.711Z"
          fill="#00AD5A"
        />
      </g>
      <defs>
        <clipPath id="clip0_25_1993">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  // const check = () => {
  //   setSendotpnumber(true);
  // };
  const token_API = userdata?.data?.data?.api_token;

  const upComingbookingData = async () => {
    setloading(true);

    try {
      const Response = await axios.post(
        "get-accepted-booking",
        {},
        {
          headers: {
            Authorization: `Bearer ${token_API}`,
          },
        }
      );

      setloading(false);
      setupComingbookingdata(Response.data.data);
    } catch (error) {
      setloading(false);
      console.log("check error  ==>", error);
    }
  };
  useEffect(() => {
    upComingbookingData();
  }, []);
  useEffect(() => {
    upComingbookingData();
  }, [MyDashboard]);

  const CompletBooking = async () => {
    setloading(true);
    try {
      const Response = await axios.post(
        "get-worker-complete-booking",
        {},
        {
          headers: {
            Authorization: `Bearer ${token_API}`,
          },
        }
      );
      setloading(false);
      // console.log("complet booking Response ========>", Response.data.data);
      setCompletBookingdata(Response?.data?.data);
    } catch (error) {
      setloading(false);
    }
  };
  useEffect(() => {
    CompletBooking();
  }, []);
  useEffect(() => {
    CompletBooking();
  }, [MyDashboard]);

  // ! for chat this code
  const chatid = generateChatId(userdata?.data?.data?.id, selectedId?.user_id);
  const messageSenderdata = {
    Myname: userdata?.data?.data?.name,
    Myid: userdata?.data?.data?.id,
    OppName: selectedId?.user_name,
    OppId: selectedId?.user_id,
    image: userdata?.data?.data?.profile_image,
    Roomid: chatid,
    Oppimage: selectedId?.user_image,
    RoomAvail: false,
  };

  // Convert object to JSON string
  const messageSenderdataJSON = JSON.stringify(messageSenderdata);

  // Store data in localStorage
  localStorage.setItem("messageSenderdata", messageSenderdataJSON);

  // !
  const handleChatboxClick = (id) => {
    setChatbox(true);
    setSelectedId(id);
  };
  const handlemodelshow = () => {
    setModalofAvaliable(true);
  };

  return (
    <>
      {/* <Navbar2 /> */}
      <Chatbox show={chatbox} onHide={() => setChatbox(false)} />
      <DetailsModals
        // show={open.istrue}
        onHide={() => setOpen({ istrue: false })}
        setOpen={setOpen}
        // upComingbookingdata={upComingbookingdata}
        open={open}
      />
      <div className="main_Dasbord_section">
        <div className="sub_dasbord_section">
          {/* dashbord heding section  */}
          <div className="dasbord_inner_box">
            <div className="dashbord_heading_div">
              <h1>Dashboard</h1>
              <h4>Bookings</h4>
              {/* <button onClick={() => handlemodelshow()}>check</button> */}
            </div>
            {/* chnageing btn section  */}
            <div className="main_btn_box">
              <div className="heading_btn_div">
                <button
                  className="das_btn"
                  style={{
                    background: MyDashboard == 0 ? "#DDFDEE" : "#fff",
                    color: MyDashboard == 0 ? "#00AD5A" : "#14161B",
                    borderRadius:
                      MyDashboard == 0 ? "10px 10px 0px 0px" : "0px",
                  }}
                  onClick={() => {
                    setMyDashbord(0);
                    getBooking();
                  }}>
                  New
                </button>
                <button
                  className="das_btn"
                  style={{
                    background: MyDashboard == 1 ? "#DDFDEE" : "#fff",
                    color: MyDashboard == 1 ? "#00AD5A" : "#14161B",
                    borderRadius:
                      MyDashboard == 1 ? "10px 10px 0px 0px" : "0px",
                  }}
                  onClick={() => {
                    setMyDashbord(1);
                    upComingbookingData();
                  }}>
                  Upcoming
                </button>
                <button
                  className="das_btn"
                  style={{
                    background: MyDashboard == 2 ? "#DDFDEE" : "#fff",
                    color: MyDashboard == 2 ? "#00AD5A" : "#14161B",
                    borderRadius:
                      MyDashboard == 2 ? "10px 10px 0px 0px" : "0px",
                  }}
                  onClick={() => {
                    setMyDashbord(2);
                    CompletBooking();
                  }}>
                  Completed
                </button>
              </div>
            </div>
          </div>

          {/* New booking  */}
          {MyDashboard == 0 ? (
            <div className="newbooking_main_section">
              <div className="new_Booking_inner_box">
                {servicebooking && servicebooking.length === 0 ? (
                  <div className="noData_div">
                    <h1>You don't have a new booking</h1>
                    <p>
                      Stay tuned! Your next booking will be displayed here as
                      soon as it’s confirmed.
                    </p>
                  </div>
                ) : (
                  <div className="new_booking_card_div">
                    {/* card start here */}
                    {servicebooking?.map((item, index) => {
                      return (
                        <div className="new_booking_card">
                          <div className="card_head_box">
                            <div className="card_img_box">
                              <img src={item.user_image} alt="" />
                            </div>
                            <div className="card_info_box">
                              <h2>{item.user_name}</h2>
                              <div className="date_time_box">
                                <div className="date_box">
                                  <h5>Date</h5>
                                  <p>
                                    {moment(item.booking_date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                </div>
                                <div className="time_box">
                                  <h5>Time</h5>
                                  <p>
                                    {moment(item.start_time, "HH:mm:ss").format(
                                      "h:mm"
                                    )}{" "}
                                    to{" "}
                                    {moment(item.end_time, "HH:mm:ss").format(
                                      "h:mm A"
                                    )}{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="card_chat_icon">
                              <span onClick={() => handleChatboxClick(item)}>
                                {chat_ico}
                              </span>
                              {/* <span onClick={() => setChatbox(true)}>
                              {chat_ico}
                            </span> */}
                            </div>
                          </div>
                          <div className="card_btn_box">
                            <button
                              className="card_btn_1"
                              onClick={() => Acceptreject(item.id, 2)}>
                              Reject
                            </button>
                            <button
                              className="card_btn_2"
                              onClick={() => Acceptreject(item.id, 1)}>
                              Accept
                            </button>
                          </div>
                          <div className="card_bottom_box">
                            <h4>₦ {item.worker_price}</h4>
                            <span
                              className="model_opne_btn"
                              onClick={() =>
                                setOpen({ istrue: true, data: item })
                              }>
                              View Full Detail
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {MyDashboard == 1 ? (
            <div className="newbooking_main_section">
              <div className="new_Booking_inner_box">
                {upComingbookingdata && upComingbookingdata.length === 0 ? (
                  <div className="noData_div">
                    <h1>You don't have a up coming booking</h1>
                    <p>
                      Stay tuned! Your next booking will be displayed here as
                      soon as it’s confirmed.
                    </p>
                  </div>
                ) : (
                  <div className="new_booking_card_div">
                    {/* card start here upComingbookingdata */}
                    {upComingbookingdata?.map((item, index) => {
                      // console.log("🚀 ~ {upComingbookingdata?.map ~ item:", item);
                      return (
                        <div className="new_Upcoming_card" key={index}>
                          <div className="card_head_box">
                            <div className="card_img_box">
                              <img src={item.user_image} alt="" />
                            </div>
                            <div className="card_info_box_Up">
                              <h2>{item.user_name}</h2>
                              <span className="upcoming">
                                {/* upc */}
                                {item.is_accepted == 1
                                  ? "Upcoming"
                                  : "Not accepted"}
                              </span>
                              <div className="date_time_box">
                                <div className="date_box">
                                  <h5>Date</h5>
                                  <p>
                                    {moment(item.booking_date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                </div>
                                <div className="time_box">
                                  <h5>Time</h5>
                                  <p>
                                    {moment(item.start_time, "HH:mm:ss").format(
                                      "h:mm"
                                    )}{" "}
                                    to{" "}
                                    {moment(item.end_time, "HH:mm:ss").format(
                                      "h:mm A"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="card_chat_icon">
                              <span onClick={() => handleChatboxClick(item)}>
                                {chat_ico}
                              </span>
                            </div>
                          </div>
                          <div className="card_btn_box">
                            <button
                              className="card_btn_1"
                              onClick={() =>
                                setOpen({ istrue: true, data: item })
                              }>
                              View Detail
                            </button>
                            <button
                              className="card_btn_2"
                              // onClick={() => check()}
                              onClick={() =>
                                setSendotpnumber({ istrue: true, data: item })
                              }>
                              Complete
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {MyDashboard == 2 ? (
            <div className="newbooking_main_section">
              <div className="new_Booking_inner_box">
                {!CompletBookingdata ? (
                  <div className="noData_div">
                    <h1>You don't have a Complet booking</h1>
                    <p>
                      Stay tuned! Your next booking will be displayed here as
                      soon as it’s confirmed.
                    </p>
                  </div>
                ) : (
                  <div className="new_booking_card_div">
                    {CompletBookingdata?.map((item, index) => {
                      return (
                        <div className="completed_card" key={index}>
                          <div className="card_head_box">
                            <div className="card_img_box">
                              <img src={item?.user_image} alt="" />
                            </div>
                            <div className="card_info_box_Up">
                              <h2>{item?.user_name}</h2>
                              <span className="Completed">
                                {item?.is_completed === 1 ? "Completed" : " "}
                              </span>
                              <div className="date_time_box">
                                <div className="date_box">
                                  <h5>Date</h5>
                                  <p>
                                    {" "}
                                    {moment(item.booking_date).format(
                                      "DD MMM YYYY"
                                    )}
                                  </p>
                                </div>
                                <div className="time_box">
                                  <h5>Time</h5>
                                  <p>
                                    {moment(item.start_time, "HH:mm:ss").format(
                                      "h:mm"
                                    )}{" "}
                                    to{" "}
                                    {moment(item.end_time, "HH:mm:ss").format(
                                      "h:mm A"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="card_chat_icon_com">
                              <h4>₦ {item.service_price}</h4>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Dashboard;
