import React, { useRef, useState, useEffect, useContext } from "react";
import "./nav.css";
import Logo from "../../../assets/svg/Logo.svg";
import hammer from "../../../assets/Hammer.png";
import { FaAngleDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BiLogIn } from "react-icons/bi";
import NavDropdown from "react-bootstrap/NavDropdown";
import Profile from "../../../assets/svg/Profile.svg";
import Hamburger from "hamburger-react";
import { toast } from "react-hot-toast";
import { Link, NavLink, useLocation } from "react-router-dom";
import axios from "../../../../Common/Api/index";
import { GlobalContext } from "../../../../GlobalContext";
import { Search } from "@mui/icons-material";
import { Backdrop, CircularProgress } from "@mui/material";

const Navbar = () => {
  const navigate = useNavigate();
  const [open, setIsopen] = useState(false);
  const [onshow, setonshow] = useState(false);
  const [loading, setloading] = useState(false);
  const [show, setshow] = useState(false);

  const userdata = JSON.parse(localStorage.getItem("login"));

  const {
    profiledata,
    setprofiledata,
    category,
    setcategory,
    userNotificationcount,
    setusernotificationcount,
    WorkerNotificationcount,
    setWorkernotificationcount,
    setLoginmodalshow,
    profilereload,
    setProfilereload,
    profileDatas,
    setpofileDatas,
  } = useContext(GlobalContext);

  const location = useLocation();

  const ico = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
        fill="white"
      />
    </svg>
  );

  const noti = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_91_3614)">
        <path
          d="M20 17H22V19H2V17H4V10C4 7.87827 4.84285 5.84344 6.34315 4.34315C7.84344 2.84285 9.87827 2 12 2C14.1217 2 16.1566 2.84285 17.6569 4.34315C19.1571 5.84344 20 7.87827 20 10V17ZM18 17V10C18 8.4087 17.3679 6.88258 16.2426 5.75736C15.1174 4.63214 13.5913 4 12 4C10.4087 4 8.88258 4.63214 7.75736 5.75736C6.63214 6.88258 6 8.4087 6 10V17H18ZM9 21H15V23H9V21Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_91_3614">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const profilee = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      id="profileico"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17_4180)">
        <path
          d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H18C18 20.4087 17.3679 18.8826 16.2426 17.7574C15.1174 16.6321 13.5913 16 12 16C10.4087 16 8.88258 16.6321 7.75736 17.7574C6.63214 18.8826 6 20.4087 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
          fill="#14161B"
          // fill={hover ? "white" : "black"}
        />
      </g>
      <defs>
        <clipPath id="clip0_17_4180">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const logout = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      id="profileico"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17_4182)">
        <path
          d="M5 22C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V6H18V4H6V20H18V18H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5ZM18 16V13H11V11H18V8L23 12L18 16Z"
          fill="#14161B"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_4182">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const toggle = () => {
    setIsopen(false);
  };
  const handlehide = () => {
    setIsopen(!open);
    // setshow(false);
  };
  const Dashbordico = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      id="profileico"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_328_4214)">
        <path
          d="M13 21V11H21V21H13ZM3 13V3H11V13H3ZM9 11V5H5V11H9ZM3 21V15H11V21H3ZM5 19H9V17H5V19ZM15 19H19V13H15V19ZM13 3H21V9H13V3ZM15 5V7H19V5H15Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_328_4214">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  let menuref = useRef();
  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsopen(false);
        }
      }
    };

    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  }, []);

  const changecategory = async (catId, subcateId) => {
    const cat_id = {
      CatId: catId,
      SubcateId: subcateId,
    };

    setshow(false);
    navigate("/Services", {
      state: {
        cat_id,
      },
    });
  };
  const Gotologin = () => {
    navigate("/Login");
  };
  const userData = JSON.parse(localStorage.getItem("login"));
  const getcategory = async () => {
    try {
      const response = await axios.get("get-category", {});

      setcategory(response.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    getcategory();
  }, []);

  const Logout = async () => {
    try {
      setloading(true);
      const response = await axios.get("logout", {
        headers: {
          Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
        },
      });

      if (response.data.message == "User logged out successfully") {
        localStorage.removeItem("login", JSON.stringify(response));

        localStorage.removeItem("lat", JSON.stringify(response));
        localStorage.removeItem("lon", JSON.stringify(response));
        localStorage.removeItem("lon", JSON.stringify(response));
        localStorage.removeItem("long", JSON.stringify(response));
        localStorage.removeItem("messageSenderdata", JSON.stringify(response));
        localStorage.removeItem("Booking Success", JSON.stringify(response));
        localStorage.removeItem("Paymentverify", JSON.stringify(response));
        localStorage.removeItem("ProfileData", JSON.stringify(response));

        toast.success(`${response.data.message}`, {
          position: "top-right",
          theme: "colored",
        });
        navigate("/");
        setprofiledata();
      }
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  const handleLogoutClick = () => {
    Logout();
  };

  //  ! get profile api calling this function
  const getProfile = async () => {
    try {
      const response = await axios.get("get-profile", {
        headers: {
          Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
        },
      });

      setprofiledata(response.data.data);
    } catch (error) {
      console.log("🚀 ~ getProfile ~ error:", error.response);
      if (error?.response?.status === 401) {
        localStorage.removeItem("login");
        localStorage.removeItem("lat");
        localStorage.removeItem("lon");
        localStorage.removeItem("lon");
        localStorage.removeItem("long");
        localStorage.removeItem("messageSenderdata");
        localStorage.removeItem("ProfileData");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (
      (location.pathname !== "/Login" && location.pathname !== "/SP_Login") ||
      (location.pathname === "/" && userdata?.data?.data?.api_token)
    ) {
      if (userdata?.data?.data?.api_token) {
        getProfile();
      }
    }
  }, [location.pathname, userdata?.data?.data?.api_token]);

  useEffect(() => {
    if (profilereload === true) {
      getProfile();
      setProfilereload(false);
    }
  }, [profilereload]);

  //! get profile api function End
  // ! use notifications count api
  const getusenoticount = async () => {
    try {
      const response = await axios.get("user-notification-count", {
        headers: {
          Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
        },
      });

      setusernotificationcount(response.data.count);
    } catch (error) {}
  };
  useEffect(() => {
    if (userData?.data?.data?.user_type === 2) {
      getusenoticount();
      const intervalId = setInterval(getusenoticount, 30000);
      return () => clearInterval(intervalId);
    }
  }, []);
  // ! use notifications count api
  // ! worker notifications count api
  const getworkernoticount = async () => {
    try {
      const response = await axios.get("worker-notification-count", {
        headers: {
          Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
        },
      });

      setWorkernotificationcount(response.data.count);
    } catch (error) {}
  };
  useEffect(() => {
    if (userData?.data?.data?.user_type === 3) {
      getworkernoticount();
      const WorkerintervalId = setInterval(getworkernoticount, 30000);
      return () => clearInterval(WorkerintervalId);
    }
  }, []);
  // ! worker notifications count api

  const homepage = "1";

  return (
    <>
      <nav id="nav" ref={menuref}>
        <div className=" nav__container">
          <div className="Logo-lap-Divvv">
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </div>
          <div className="Logo-Phone-Divv">
            <Link to="/">
              <img src={hammer} alt="" />
            </Link>
          </div>
          <div className="Search-login-Nav-divv">
            <div className="srch-loin-pc">
              <NavLink
                to="/Search"
                onClick={toggle}
                style={{ paddingTop: "4px" }}>
                {ico}
              </NavLink>

              <NavLink
                to="/Profile/Notification"
                onClick={toggle}
                className="notification">
                {noti}
                <span class="badge">{userNotificationcount}</span>
              </NavLink>

              {userData ? (
                <div className="btn_div">
                  <div className="profile_div">
                    <div className="profile_pic_box">
                      <img
                        src={
                          profileDatas?.profile_image
                            ? profileDatas?.profile_image
                            : profileDatas.image
                            ? profileDatas.image
                            : Profile
                        }
                        alt=""
                      />
                    </div>

                    <div className="dropdown_div">
                      <NavDropdown
                        id="nav-dropdown-dark-example"
                        // title={profiledata?.name}
                        className="drop_tittle"
                        // style={
                        //   "position: absolute; inset: 19px 6px auto auto;transform: translate3d(-16.5px, 6px, 0px);"
                        // }
                        menuVariant="dark">
                        <NavDropdown.Item
                          href="/Profile/EditProfile"
                          className="drop_itme">
                          {profilee} My Profile
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item
                          // href="/HandyConnect/"
                          onClick={handleLogoutClick}
                          className="drop_itme">
                          {logout}Logout
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                  </div>
                </div>
              ) : (
                <button
                  className="loginbtn"
                  onClick={() =>
                    navigate("/Login", {
                      state: {
                        homepage,
                      },
                    })
                  }>
                  <BiLogIn className="loginicon" />
                </button>
              )}
            </div>

            <ul className={open ? "nav_menu mobilemenu" : "nav_menu"}>
              <li>
                <NavLink className="nav__link" to="/" onClick={toggle}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="nav__link"
                  to="/HowitWorks"
                  onClick={toggle}>
                  How it Works
                </NavLink>
              </li>

              {/* service drop dowm  start hare */}
              <div className="dropdown">
                <button
                  onClick={() => setshow((prev) => !prev)}
                  className={`btn btn-secondary dropdown-toggle ${
                    show ? "show" : ""
                  }`}
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-auto-close="outside"
                  data-bs-toggle="dropdown"
                  aria-expanded={show}>
                  Services
                </button>
                <ul
                  className={`dropdown-menu ${show ? "show" : ""}`}
                  aria-labelledby="dropdownMenuButton1">
                  {category.map((item) => (
                    <li className="dropend">
                      <a
                        className="dropdown-item dropdown-toggle"
                        data-bs-toggle="dropdown"
                        href="#">
                        {item.CatName}
                      </a>
                      <ul className="dropdown-menu .dropdownsecond">
                        {item.Subcategory.map((element) => (
                          <li
                            onClick={(event) => {
                              event.preventDefault();
                              changecategory(item.CatId, element.SubcateId);
                            }}>
                            <a
                              className="dropdown-item"
                              href="#"
                              key={element.SubcateId}>
                              {element.SubcateName}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
              {/* service drop dowm  end hare */}
              <li>
                <NavLink className="nav__link" to="/ContactUs" onClick={toggle}>
                  Contact Us
                </NavLink>
              </li>

              {userData ? (
                userData.data.data.user_type === 2 ? (
                  <div className="Srch-noti-guy-divv">
                    <li>
                      <NavLink
                        to="/Search"
                        className="searchh"
                        onClick={toggle}>
                        {ico}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/Profile/Notification"
                        onClick={toggle}
                        className="notification">
                        {noti}
                        <span class="badge">{userNotificationcount}</span>
                      </NavLink>
                    </li>
                    <div className="btn_div">
                      <div className="profile_div">
                        <div className="profile_pic_box">
                          <img
                            src={
                              profileDatas?.profile_image
                                ? profileDatas?.profile_image
                                : profileDatas.image
                                ? profileDatas.image
                                : Profile
                            }
                            alt=""
                          />
                        </div>
                        <div className="dropdown_div">
                          <NavDropdown
                            id="nav-dropdown-dark-example"
                            title={profileDatas?.name}
                            className="drop_tittle"
                            menuVariant="dark">
                            <NavDropdown.Item
                              href="/Profile/EditProfile"
                              className="drop_itme">
                              {profilee} My Profile
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              // href="/HandyConnect/"
                              onClick={handleLogoutClick}
                              className="drop_itme">
                              {logout}Logout
                            </NavDropdown.Item>
                          </NavDropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="Srch-noti-guy-divv">
                    <li>
                      <NavLink to="/Search" onClick={toggle}>
                        {ico}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/SP_Profile/Notification"
                        onClick={toggle}
                        className="notification">
                        {noti}
                        <span class="badge">{WorkerNotificationcount}</span>
                      </NavLink>
                    </li>
                    <div className="btn_div">
                      <div className="profile_div">
                        <div className="profile_pic_box">
                          <img
                            src={
                              profileDatas?.profile_image
                                ? profileDatas?.profile_image
                                : profileDatas.image
                                ? profileDatas.image
                                : Profile
                            }
                            alt=""
                          />
                        </div>
                        <div className="dropdown_div">
                          <NavDropdown
                            id="nav-dropdown-dark-example"
                            title={profileDatas?.name}
                            className="drop_tittle"
                            menuVariant="dark">
                            <NavDropdown.Item
                              href="/SP_Profile"
                              className="drop_itme">
                              {profilee} My Profile
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              href="/SP_Dashboard"
                              className="drop_itme">
                              {Dashbordico} Dashbord
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                              onClick={() => handleLogoutClick()}
                              className="drop_itme">
                              {logout}Logout
                            </NavDropdown.Item>
                          </NavDropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <>
                  {/* <li>
                    <NavLink to="/Search" onClick={toggle}>
                      {ico}
                    </NavLink>
                  </li> */}
                  <div className="Login-search-green">
                    <button
                      className="btn1 primary"
                      onClick={() =>
                        navigate("/Login", {
                          state: {
                            homepage,
                          },
                        })
                      }>
                      Login
                    </button>
                  </div>
                  <div className="btn_div">
                    <button
                      className="btn2"
                      onClick={() => navigate("/SP_Login")}>
                      Become a Service Provider
                    </button>
                  </div>
                </>
              )}
            </ul>
            <div className="menubtn">
              <Hamburger onToggle={handlehide} toggled={open} />
            </div>
          </div>
        </div>
      </nav>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Navbar;
