import React, { useEffect, useState } from "react";
// import "../Settingss/Settings.css";
// import axios from "../../../../Common/Api/index";
import axios from "../../../Common/Api/index";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [isOpen, setIsOpen] = useState();
  // console.log("🚀 ~ Settings ~ isOpen:", isOpen);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const toggleButton = () => {
    setIsToggled(!isToggled);
  };

  const userdata = JSON.parse(localStorage.getItem("login"));
  // console.log("🚀 ~ Settings ~ userdata:", userdata?.data?.data?.api_token);
  const Getsetting = async () => {
    setloading(true);
    try {
      const response = await axios.get(
        "notification-status",

        {
          headers: {
            Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
          },
        }
      );

      setIsOpen(response.data.is_notification);
      // console.log("getseting response====>", response.data.is_notification);
      setloading(false);
    } catch (error) {
      setloading(false);
      // console.log("getseting error===>", error);
    }
  };
  useEffect(() => {
    Getsetting();
  }, []);

  const updatesetings = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "notification-on-Off",
        { is_notification: isOpen },
        {
          headers: {
            Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
          },
        }
      );
      // console.log("🚀 ~ updatesetings ~ response:", response);

      setloading(false);
    } catch (error) {
      setloading(false);
      if (error?.response?.status === 401) {
        localStorage.removeItem("login");
        localStorage.removeItem("lat");
        localStorage.removeItem("lon");
        localStorage.removeItem("lon");
        localStorage.removeItem("long");
        localStorage.removeItem("messageSenderdata");
        navigate("/");
      }
    }
  };

  useEffect(() => {
    updatesetings();
  }, [isOpen]);

  // console.log(isOpen);

  return (
    <>
      <div className="All-Conatinor-perfect-divv">
        <div className="All-Containor-perfect-second-divv">
          <div className="Settings-Main-Span-divv">
            <span className="Settikgs-main-span">Settings</span>
          </div>
          <div className="Email-and-SmS-Main_divv">
            <div className="Email-with-underline-Divv">
              <div className="SMS-Notification-divv">
                <div className="Email-spann-tagg-divvv">
                  <span className="Spann-Off-email">SMS Notification</span>
                </div>

                <button
                  className={`round-switch-button ${isOpen ? "active" : ""}`}
                  onClick={() => setIsOpen((prev) => (prev === 1 ? 0 : 1))}
                  // onChange={handlechange}
                >
                  <div className={`slider ${isOpen ? "active" : ""}`}></div>
                </button>
              </div>

              <div className="Underline-Divv-Email"></div>
              <button onClick={() => Getsetting()}></button>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Settings;
