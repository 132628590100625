import React, { useState, useEffect } from "react";
import "./Notification.css";
import { toast } from "react-hot-toast";
import { Backdrop, CircularProgress } from "@mui/material";
import axios from "../../../../Common/Api/index";
import noti1 from "../../../assets/Notification/Notification1_1.png";
import noti2 from "../../../assets/Notification/Notification1_2.png";
import noti3 from "../../../assets/Notification/Notification1_3.png";
import noti4 from "../../../assets/Notification/Notification1_4.png";
import noti5 from "../../../assets/Notification/Notification1_5.png";
import noti6 from "../../../assets/Notification/Notification1_6.png";
import noti7 from "../../../assets/Notification/Notification1_7.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const [notiData, setnotiData] = useState([]);
  // console.log("🚀 ~ Index ~ notiData:", notiData);
  const [loading, setloading] = useState(false);
  const userdata = JSON.parse(localStorage.getItem("login"));
  const token_API = userdata?.data?.data?.api_token;
  // console.log("🚀 ~ Index ~ token_API:", token_API);
  const navigate = useNavigate();
  const getNotiftion = async () => {
    setloading(true);
    try {
      const Response = await axios.get(
        "get-user-notification",

        {
          headers: {
            Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
          },
        }
      );
      // console.log("🚀 ~ getNotiftion ~ Response:", Response.data);
      setloading(false);
      setnotiData(Response.data.data);
      // if (Response.data.status === 1) {
      //   toast.success(Response.data.message, {
      //     position: "top-right",
      //     theme: "colored",
      //   });
      // }
    } catch (error) {
      setloading(false);
      if (error?.response?.status === 401) {
        localStorage.removeItem("login");
        localStorage.removeItem("lat");
        localStorage.removeItem("lon");
        localStorage.removeItem("lon");
        localStorage.removeItem("long");
        localStorage.removeItem("messageSenderdata");
        navigate("/");
      }
      // console.log("🚀 ~ getNotiftion ~ error:", error);
    }
  };
  useEffect(() => {
    getNotiftion();
  }, []);

  const Notificationdata = [
    {
      id: 1,
      Noti_pic: noti1,
      User_name: "Kristin Watson",
      Notificatio_Msg:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, nostrum nulla? Neque ut labore sit! Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur",
      date: "22 jan 2023",
    },
    {
      id: 2,
      Noti_pic: noti2,
      User_name: "Darrell Steward",
      Notificatio_Msg:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, nostrum nulla? Neque ut labore sit! Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur",
      date: "22 jan 2023",
    },
    {
      id: 3,
      Noti_pic: noti3,
      User_name: "Albert Flores",
      Notificatio_Msg:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, nostrum nulla? Neque ut labore sit! Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur",
      date: "22 jan 2023",
    },
    {
      id: 4,
      Noti_pic: noti4,
      User_name: "Jerome Bell",
      Notificatio_Msg:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, nostrum nulla? Neque ut labore sit! Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur",
      date: "22 jan 2023",
    },
    {
      id: 5,
      Noti_pic: noti5,
      User_name: "Ronald Richards",
      Notificatio_Msg:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, nostrum nulla? Neque ut labore sit! Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur",
      date: "22 jan 2023",
    },
    {
      id: 6,
      Noti_pic: noti6,
      User_name: "Marvin McKinney",
      Notificatio_Msg:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, nostrum nulla? Neque ut labore sit! Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur",
      date: "22 jan 2023",
    },
    {
      id: 7,
      Noti_pic: noti7,
      User_name: "Jane Cooper",
      Notificatio_Msg:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, nostrum nulla? Neque ut labore sit! Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur",
      date: "22 jan 2023",
    },
  ];

  const readmore = (
    <svg
      width="15"
      height="20"
      viewBox="0 0 534 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M466.999 67.0001H467.333M266.999 67.0001H267.333M66.9993 67.0001H67.3327M300.333 67.0001C300.333 85.4101 285.409 100.333 266.999 100.333C248.589 100.333 233.666 85.4101 233.666 67.0001C233.666 48.5901 248.589 33.6667 266.999 33.6667C285.409 33.6667 300.333 48.5901 300.333 67.0001ZM500.333 67.0001C500.333 85.4101 485.409 100.333 466.999 100.333C448.589 100.333 433.666 85.4101 433.666 67.0001C433.666 48.5901 448.589 33.6667 466.999 33.6667C485.409 33.6667 500.333 48.5901 500.333 67.0001ZM100.333 67.0001C100.333 85.4101 85.4087 100.333 66.9993 100.333C48.59 100.333 33.666 85.4101 33.666 67.0001C33.666 48.5901 48.59 33.6667 66.9993 33.6667C85.4087 33.6667 100.333 48.5901 100.333 67.0001Z"
        stroke="#1A59F3"
        stroke-width="66.6667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  const closed = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_384_5443)">
        <path
          d="M12.0007 10.586L16.9507 5.63599L18.3647 7.04999L13.4147 12L18.3647 16.95L16.9507 18.364L12.0007 13.414L7.05072 18.364L5.63672 16.95L10.5867 12L5.63672 7.04999L7.05072 5.63599L12.0007 10.586Z"
          fill="#1A59F3"
        />
      </g>
      <defs>
        <clipPath id="clip0_384_5443">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  // State to track expanded state for each notification
  const [expandedNotifications, setExpandedNotifications] = useState(
    notiData?.map(() => false)
  );

  // Function to toggle the expanded state for a notification
  const toggleExpansion = (index) => {
    const newExpandedNotifications = [...expandedNotifications];
    newExpandedNotifications[index] = !newExpandedNotifications[index];
    setExpandedNotifications(newExpandedNotifications);
  };

  // State to track screen width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="All-Conatinor-perfect-divv">
        <div className="All-Containor-perfect-second-divv">
          <div className="main_notification_div">
            <div className="edit-profile-double-div">
              <span className="Settikgs-main-span">Notification</span>
            </div>
            <div className="main_noti_info_container">
              {notiData?.map((item, index) => {
                const isExpanded = expandedNotifications[index];

                return (
                  <div className="notification-box" key={item.id}>
                    <div className="sub_Notifi_box">
                      <div className="Notification_img_box">
                        <img src={item.worker_image} alt="Profile_Pic" />
                      </div>
                      <div className="Notification_text_div">
                        <div className="Noti_msg">
                          <h1>{item.worker_name}</h1>
                          <p className="Noti_title">{item.title}</p>
                          <span>
                            {windowWidth <= 768 && !isExpanded
                              ? item.message.slice(0, 20)
                              : item.message}
                          </span>
                          {windowWidth <= 768 && !isExpanded && (
                            <button
                              onClick={() => toggleExpansion(index)}
                              className="Read_btn">
                              Read more
                            </button>
                          )}
                          {windowWidth <= 768 && isExpanded && (
                            <button
                              onClick={() => toggleExpansion(index)}
                              className="cls_btn">
                              {closed}
                            </button>
                          )}
                        </div>
                        <div className="dates_div_inf0">
                          {item.updated_at === null ? null : (
                            <span>
                              {moment(item.updated_at).format(
                                "MMMM Do YYYY, h:mm a"
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bottom_line_box"></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Index;
