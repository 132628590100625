import React, { useContext, useEffect } from "react";
import "./payment.css";
import Footer from "../../components/UI/Footer";
import tick from "../../assets/svg/Successfully.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import image from "../../assets/Nabil.png";

const Index = () => {
  const { idget, setSuccessdata, Successdata } = useContext(GlobalContext);

  const navigate = useNavigate();
  const location = useLocation();

  // const Successdata = JSON.parse(localStorage.getItem("Booking Success"));
  // useEffect(() => {
  //   if (Paymentverify === 1) {
  //     navigate("/Successfully");
  //   }
  // }, []);
  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.href);
  //   window.onpopstate = function () {
  //     window.history.go(1);
  //   };
  // }, []);
  const handleclick = () => {
    // localStorage.removeItem("Paymentverify");
  };

  return (
    <>
      <div className="main_pay_div">
        <div className="sub_pay_main_div">
          {/* <div className="_img__box">
            <span>{paymentIcon}</span>
          </div>
          <div className="text">
            <h2>Want to Book ?</h2>
            <p>Please click below button and Make Your payemnt </p>
          </div>

          <a
            href={Successdata?.paystack_response?.authorization_url}
            className="pytn_1">
            Pay Now
          </a> */}

          <div className="paymnet_card">
            <div className="div_pay_hed">
              <h2>Booked Service Details</h2>
            </div>
            <div className="div1_pay">
              <div className="pay_inner_div">
                <div className="div_of_img_pay">
                  <img src={Successdata?.worker_image} alt="" />
                </div>
                <div className="pay_details">
                  <h2>{Successdata?.worker_name}</h2>
                  <p>{Successdata?.cat_name} </p>
                  <p className="cat_p">{Successdata?.subcat_name} </p>
                </div>
              </div>
            </div>
            <div className="div2_pay">
              <div className="text">
                <h2>Book this Service ?</h2>
                <p>Please click below button and Make Your Payment </p>
              </div>
            </div>
            <a
              href={Successdata?.paystack_response?.authorization_url}
              className="pytn_1"
              onClick={() => handleclick()}>
              Pay ₦ {Successdata?.price}
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Index;
