import "./sec7.css";

import React, { useRef, useState, useContext, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import sec2data from "../section2/sec2data";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../GlobalContext";
import axios from "../../../../Common/Api/index.jsx";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import demo from "../../../assets/svg/Profile.svg";
import defult_image from "../../../assets/svg/Profile.svg";

const Section7 = () => {
  const { setLoginmodalshow } = useContext(GlobalContext);
  const [Expertsdata, setExpertdata] = useState();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const SwiperRef = useRef(null);
  const Prev = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_35_9001)">
        <path
          className="mySvgPath"
          d="M11.828 12L14.657 9.17202L13.243 7.75702L8.99998 12L13.243 16.243L14.657 14.828L11.828 12Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_9001">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
  const next = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_32)">
        <path
          className="mySvgPath"
          d="M12.172 12L9.34299 9.17202L10.757 7.75702L15 12L10.757 16.243L9.34299 14.828L12.172 12Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_32">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const start = (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_35_8969)">
        <path
          d="M7.50001 10.625L3.82626 12.8687L4.82501 8.68125L1.55626 5.88125L5.84688 5.5375L7.50001 1.5625L9.15313 5.5375L13.4444 5.88125L10.175 8.68125L11.1738 12.8687L7.50001 10.625Z"
          fill="#F2B006"
        />
      </g>
      <defs>
        <clipPath id="clip0_35_8969">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  const userData = JSON.parse(localStorage.getItem("login"));
  const Lat = JSON.parse(localStorage.getItem("lat"));
  const Long = JSON.parse(localStorage.getItem("long"));
  const findExperts = async () => {
    setloading(true);
    try {
      const Response = await axios.post("find-expert", {
        latitude: Lat,
        longitude: Long,
      });
      setExpertdata(Response.data.Experts);
      setloading(false);
      // console.log("Response of sec 7 -===-======> ", Response.data.Experts);
    } catch (error) {
      // console.log(error.message);
    }
  };
  useEffect(() => {
    findExperts();
  }, []);
  const handleclick = async (item) => {
    // console.log("🚀 ~ handledetail ~ item:", item);
    navigate("/WorkerProfile", {
      state: {
        item,
      },
    });
  };

  return (
    <section className="sec7">
      <div className="container">
        <div className="hheading__sec2">
          <h2>Find our Experts</h2>
          <div className="div_bttn">
            <button
              className="swiper_btn"
              type="button"
              onClick={() => SwiperRef.current.swiper.slidePrev()}>
              <span>{Prev}</span>
            </button>
            <button
              className="swiper_btn"
              type="button"
              onClick={() => SwiperRef.current.swiper.slideNext()}>
              <span>{next}</span>
            </button>
          </div>
        </div>

        <div className="main_swiper_div">
          <Swiper
            className={loading ? "main_swiper_div_box" : null}
            ref={SwiperRef}
            slidesPerView={6}
            spaceBetween={10}
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 50,
              },
            }}
            style={{ width: "100%" }}>
            {loading
              ? Array(6)
                  .fill(0)
                  .map((_, index) => (
                    <div key={index}>
                      <SkeletonTheme
                        baseColor="#949495"
                        highlightColor="#D1D1D1">
                        <Skeleton style={{ width: "200px", height: "300px" }} />
                      </SkeletonTheme>
                    </div>
                  ))
              : Expertsdata?.map((item, index) => {
                  // console.log("🚀 ~ Section7 ~ item:", item);
                  return (
                    <SwiperSlide key={index}>
                      <div className="sec7_swiper_main_box">
                        <div className="div_of_img_sec7">
                          <img
                            src={item.image ? item.image : defult_image}
                            className="img_sec7"
                            alt="worker_profile"
                          />{" "}
                        </div>
                        <div className="sec7_text_box">
                          <h6>{item.name}</h6>
                          <p className="p__text">
                            {start}
                            {Math.round(item.average_rate)}({item.rate_count})
                          </p>

                          {/* <p className="sub_cat_name">
                            {item.subcategory_name}
                          </p> */}
                          <p>
                            {item.subcategory_name.length > 18
                              ? `${item.subcategory_name.substring(0, 18)}...`
                              : item.subcategory_name}
                          </p>
                          <button
                            // onClick={() =>
                            //   userData
                            //     ? handleclick(item)
                            //     : setLoginmodalshow(true)
                            // }
                            onClick={() => handleclick(item)}
                            className="btn4">
                            Book Now
                          </button>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Section7;
