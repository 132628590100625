import React, { useState, useContext, useEffect } from "react";
import "./Professional.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ProfessionalSchemas } from "../../../schemas/index.jsx";
import close from "../../../User/assets/svg/Close.svg";
import { GlobalContext } from "../../../GlobalContext";
import axios from "../../../Common/Api";
import { toast } from "react-hot-toast";
import { ImageList } from "@mui/material";
import { Backdrop, CircularProgress } from "@mui/material";
import { createUserWithEmailAndPassword } from "firebase/auth";
// import upload from "../../../Common/Firebase/upload";
import { db } from "../../../../src/Common/Firebase/firebase";
import { collection, doc, setDoc } from "firebase/firestore";
import {
  GoogleProvider,
  FacebookProvider,
  auth,
} from "../../../Common/Firebase/firebase";

const initialValues = {
  RatePerhour: "",
  Writehere: "",
  ResolutionExperience: "",
  Skills: "",
  SelectedCatId: "",
  SelectedSubcatId: "",
};

const Professional = ({ selectedImage }) => {
  const Navigate = useNavigate();

  const { RegisterdataShow, category } = useContext(GlobalContext);

  const [skills, setSkills] = useState([]);
  const [imageCount, setImageCount] = useState(0);
  const [images, setImages] = useState([]);

  const [newSkill, setNewSkill] = useState([]);

  const [specialist, setSpecialist] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedCatId, setSelectedCatId] = useState("");

  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcatId, setSelectedSubcatId] = useState("");
  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);

    if (images.length + selectedImages.length > 3) {
      // Show a toast and only allow up to 5 images
      toast.error("You can only select a maximum of 3 images", {
        position: "top-right",
        theme: "colored",
      });

      // Limit the selected images to fit within the 5 image limit
      const limitedImages = selectedImages.slice(0, 3 - images.length);

      // Update the state with the allowed images
      setImages([...images, ...limitedImages]);
      setImageCount(imageCount + limitedImages.length);
    } else {
      // If within the limit, allow all selected images
      setImages([...images, ...selectedImages]);
      setImageCount(imageCount + selectedImages.length);
    }
  };

  const handimgleDelete = (index) => {
    setImages(images.filter((image, i) => i !== index));
    setImageCount(imageCount - 1);
  };

  const handleAddSkill = () => {
    if (newSkill.trim() !== "") {
      setSkills([...skills, newSkill]);
      setNewSkill("");
    }
  };

  const addIconSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#1E2D38">
      <path d="M10 2V10H18V12H10V20H8V12H0V10H8V2H10Z" />
    </svg>
  );
  const GetSpeciality = async () => {
    try {
      const response = await axios.get("get-category", {
        headers: {
          "Content-Type": "application/json",
        },
      });

      setSpecialist(response.data.category);
    } catch (error) {}
  };
  useEffect(() => {
    GetSpeciality();
  }, []);

  const name = skills.map((item) => item.toString()).join(", ");

  const MYDeviceToken = JSON.parse(localStorage.getItem("currentToken"));
  const onSubmit = async () => {
    setloading(true);

    const formdata = new FormData();
    formdata.append("name", RegisterdataShow.name);
    formdata.append("phone", RegisterdataShow.phone);
    formdata.append("password", RegisterdataShow.password);
    formdata.append("email", RegisterdataShow.email);
    formdata.append("user_type", "3");
    formdata.append("ratePerhour", values.RatePerhour);
    formdata.append("description", values.Writehere);
    formdata.append("skil", name);
    formdata.append("cat_id", selectedCatId);
    formdata.append("subcate_id", selectedSubcatId);
    formdata.append("experience", values.ResolutionExperience);
    images.forEach((imggg) => formdata.append("worker_img[]", imggg));
    formdata.append("profile_image", RegisterdataShow.image);

    for (const value of formdata.entries()) {
    }
    try {
      let config = {
        url: "register",
        method: "post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(config)
        .then((response) => {
          setloading(false);

          if (response.data.status === "failed") {
            toast.error(response.message, {
              position: "top-right",
              theme: "colored",
            });
          } else if (response.status === "success") {
            toast.success(response.message, {
              position: "top-right",
              theme: "colored",
            });
          }

          if (response.data.message === "Worker Register Successfully") {
            // setRegister(false);
            Navigate("/SP_Login");
            // for send to firestore.....................
            const data = {
              userrole: response.data.data.user_type,
              name: response.data.data.name ?? "",
              email: response.data.data.email,
              id: response.data.data.detail.id,
              image:
                response.data.data.profile_image !== null
                  ? response.data.data.profile_image
                  : "",
              fcmtoken: MYDeviceToken,
              updatedat: new Date(),
            };
            // firebase query.................
            const doref = doc(
              collection(db, "users"),
              JSON.stringify(response.data.data.api_token)
            );
            setDoc(doref, data)
              .then((rrr) => {
                // console.log(rrr);
              })
              .catch((error) => {
                // console.error("Error setting data in Firestore:", error);
              });
          }
        })
        .catch((error) => {
          console.log("🚀 ~ onSubmit ~ error:", error);
          setloading(false);
          toast.error(error.response.data.message, {
            position: "top-right",
            theme: "colored",
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ProfessionalSchemas,
    onSubmit,

    // onSubmit: (values) => {
    //   console.log("Values.....!", values);
    // },
  });
  // console.log(values.YourSpeciality, "aaaajaaaaaa");
  // console.log(skills, "show skil");
  // console.log("hhhhhhhhhh", images);
  const handleSkillChange = (event) => {
    setNewSkill(event.target.value);
    setFieldValue("Skills", event.target.value);
  };
  const handleCancelSkill = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    setSkills(updatedSkills);
    if (updatedSkills.length === 0) {
      setFieldValue("Skills", "");
    }
  };
  const handleCategoryChange = (e) => {
    const catId = e.target.value;
    setSelectedCatId(catId);
    setFieldValue("SelectedCatId", catId);

    const selectedCategory = category.find(
      (cat) => cat.CatId.toString() === catId
    );
    if (selectedCategory) {
      setSubcategories(selectedCategory.Subcategory);
    } else {
      setSubcategories([]);
    }
  };
  const handleSubcategoryChange = (e) => {
    setSelectedSubcatId(e.target.value);
    setFieldValue("SelectedSubcatId", e.target.value);
  };

  return (
    <>
      <div className="RegisterTwo-main-sec">
        <form onSubmit={handleSubmit} className="RegisterTwo-main-div">
          <span className="mb-4 personaly-add mt-4">Professional Info</span>
          <div className="input-from-r mt-3">
            <span className="all-input-span-font">Rate (Per hour)</span>
            <div className="doler-ka-liya mt-2">
              <span className="doler-span">$</span>
              <input
                className="ya-bot-input"
                placeholder=""
                type="Number"
                value={values.RatePerhour}
                onBlur={handleBlur}
                name="RatePerhour"
                min="10"
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  const value = e.target.value;

                  // Allow input if it matches regex or is empty (allowing deletion)
                  if (
                    value === "" ||
                    (re.test(value) && parseInt(value) <= 1000000)
                  ) {
                    handleChange(e);
                  }
                }}
                maxLength="7"
              />
            </div>
            {errors.RatePerhour && touched.RatePerhour ? (
              <p className="mastu-to-eroor mt-2">{errors.RatePerhour} </p>
            ) : null}
            <span className="mt-3 all-input-span-font">About You</span>
            <textarea
              className="mt-2 Write-here-mahu"
              id="w3review"
              name="Writehere"
              placeholder="Write here"
              rows="6"
              cols="40"
              value={values.Writehere}
              onChange={handleChange}
              onBlur={handleBlur}></textarea>
            {errors.Writehere && touched.Writehere ? (
              <p className="mastu-to-eroor mt-2">{errors.Writehere} </p>
            ) : null}
            <span className="all-input-span-font mt-3">Skills</span>
            <div className="doler-ka-liya mt-2">
              <div className="Lorem-btn">
                <div className="Lorem-btn">
                  <div className="add-skill-input">
                    <input
                      className="add-skill-input-field"
                      type="text"
                      placeholder="Add new skill"
                      name="Skills"
                      value={newSkill}
                      onChange={handleSkillChange}
                      onBlur={handleBlur}
                    />
                    <button
                      className="add-skill-btn"
                      onClick={handleAddSkill}
                      dangerouslySetInnerHTML={{ __html: addIconSvg }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="Skills-main-divv-ppx mt-2">
              {skills?.map((skill, index) => {
                return (
                  <div key={index} className="skill-item">
                    <span className="skill-text">{skill}</span>
                    <img
                      className="remove-skill-icon"
                      src={close}
                      onClick={() => handleCancelSkill(index)}
                    />
                  </div>
                );
              })}
            </div>
            {errors.Skills && touched.Skills ? (
              <p className="mastu-to-eroor mt-2">{errors.Skills}</p>
            ) : null}
            <span className="all-input-span-font mt-3">Your Speciality</span>
            <div className="selact-ka-liya-jugad mt-2">
              <select
                className="selact-tag-one"
                name="SelectedCatId"
                value={selectedCatId}
                onChange={handleCategoryChange}
                // onChange={(e) => setselectID(e.target.value)}
                placeholder="Select Service">
                <option value="" disabled selected hidden>
                  Select Service
                </option>
                {category.map((item, index) => {
                  return (
                    <option key={index} value={item.CatId}>
                      {item.CatName}
                    </option>
                  );
                })}
              </select>
            </div>
            {errors.SelectedCatId && touched.SelectedCatId ? (
              <p className="mastu-to-eroor mt-2">{errors.SelectedCatId} </p>
            ) : null}
            <div className="selact-ka-liya-jugad mt-2">
              <select
                className="selact-tag-one"
                name="SelectedSubcatId"
                value={selectedSubcatId}
                onChange={handleSubcategoryChange}
                placeholder="Select Service">
                <option value="" disabled selected hidden>
                  Select Sub Service
                </option>
                {subcategories.map((subcat) => (
                  <option key={subcat.SubcateId} value={subcat.SubcateId}>
                    {subcat.SubcateName}
                  </option>
                ))}
              </select>
            </div>

            {errors.SelectedSubcatId && touched.SelectedSubcatId ? (
              <p className="mastu-to-eroor mt-2">{errors.SelectedSubcatId} </p>
            ) : null}
            <span className="mt-3 all-input-span-font">YourExperience</span>
            <textarea
              className="mt-2 Write-here-mahu"
              id="w3review"
              name="ResolutionExperience"
              placeholder="Write here"
              rows="6"
              cols="40"
              value={values.ResolutionExperience}
              onChange={handleChange}
              onBlur={handleBlur}></textarea>
            {errors.ResolutionExperience && touched.ResolutionExperience ? (
              <p className="mastu-to-eroor mt-2">
                {errors.ResolutionExperience}{" "}
              </p>
            ) : null}
            <span className="mt-3">Upload Work Photos</span>
            <div className="add-delet-option">
              <label className="Ht-ml-for" htmlFor={"mal"}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 9V5H11V9H15V11H11V15H9V11H5V9H9ZM10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18Z"
                    fill="#00AD5A"
                  />
                </svg>
              </label>
              <input
                id="mal"
                type="file"
                multiple
                onChange={handleImageChange}
                className="Malte-pal-cls"
              />
              <div className="Not-overflowing">
                <div className="overflowing-add-immgg">
                  {images?.map((imagess, index) => {
                    return (
                      <div className="sab-img-ka-div">
                        <img
                          src={URL.createObjectURL(imagess)}
                          alt={`Image-${index}`}
                          className="add-kiya-huva-pic"
                        />
                        <button
                          className="Btn-dada-delet"
                          onClick={() => handimgleDelete(index)}>
                          <svg
                            className="Ma-svg-top"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_25_3172)">
                              <path
                                d="M7.0014 6.17516L9.8889 3.28766L10.7137 4.11249L7.82623 6.99999L10.7137 9.88749L9.8889 10.7123L7.0014 7.82483L4.1139 10.7123L3.28906 9.88749L6.17656 6.99999L3.28906 4.11249L4.1139 3.28766L7.0014 6.17516Z"
                                fill="#211B24"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_25_3172">
                                <rect width="14" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* <span className="mt-3 Certificate-span">
            Add more Documents/Certificate
          </span> */}
          <button type="submit" className="cponform-regiestar mt-3">
            Register
          </button>
          <div className="hghu"></div>
        </form>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Professional;
