import { useState, useContext, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Landingpage from "./User/Pages/Landingpage/index";
import Personalinformation from "./ServiceProvider/components/Personalinformation/Personalinformation.jsx";
import Professional from "./ServiceProvider/components/Professional/Professional";
import Search from "./User/components/Search/Search";
import BookProject from "./User/components/BookProject/BookProject";
import DealsNearYou from "./User/components/DealsNearYou/DealsNearYou";
import WorkerProfile from "./User/components/WorkerProfile/WorkerProfile";
import Dealsdeatail from "./User/Pages/Deals Detail";
import Successfully from "./User/components/Successfully /Successfully";
import Paymnetfaild from "./User/components/Paymnetfaild ";
import Login from "./User/Pages/Auth/Login/Login";
import Register from "./User/Pages/Auth/Register/Register";
import ForgotPassword from "./User/Pages/Auth/ForgotPassword/ForgotPassword";
import Profile from "./User/Pages/Profile";
import Profilesidebar from "./User/components/UI/ProfileSidebar/Profilesidebar";
import EditProfile from "./User/Pages/Profile/EditProfile/EditProfile";
import PaymentOptions from "./User/Pages/Profile/PaymentOptions/PaymentOptions.jsx";
import MyBookingsUpcoming from "./User/Pages/Profile/MyBookings/MyBookingsUpcoming";
import Settings from "./User/Pages/Profile/Settingss/Settings";
import Chatscreen from "./User/Pages/Chatscreen/Chatscreen";
import Contactus from "./User/Pages/Contactus/Contactus";
import Aboutus from "./User/Pages/Aboutus";
import SP_Landingpage from "./ServiceProvider/Pages/SP_Landingpage";
import SP_login from "./ServiceProvider/Pages/Auth/Login/Login.jsx";
import SP_Register from "./ServiceProvider/Pages/Auth/Register_SP";
import Successfullycreated from "./ServiceProvider/components/Successfully_Created/SuccessfullyCreated";
import Dashboard from "./ServiceProvider/Pages/Dashboard";
import EditProfile2 from "./ServiceProvider/Pages/Profile2/EditProfile2/EditProfile2";
import MyWallet from "./ServiceProvider/Pages/Profile2/MyWallet/MyWallet";
import ProfileSdebar from "./ServiceProvider/components/UI/ProfileSideBar2/ProfileSdebar2";
import Profile2 from "./ServiceProvider/Pages/Profile2/Profile2";
import Services from "./User/Pages/Services/index.jsx";
import SP_Aboutus from "./ServiceProvider/Pages/Aboutus";
import Chatscreen2 from "./ServiceProvider/Pages/SP_ChatScreen/Chatscreen2";
import Settings2 from "./ServiceProvider/Pages/Settings2/Settings2";
import Notification from "./User/Pages/Profile/Notification/Index.jsx";
import SP_Notification from "./ServiceProvider/Pages/Profile2/SP_Notification/Index.jsx";
import SP_Contactus from "./ServiceProvider/Pages/Contactus/Contactus.jsx";
import Terms from "./User/Pages/Policy/Terms of Use/Index.jsx";
import Support from "./User/Pages/Policy/Support/Index.jsx";
import Privacy from "./User/Pages/Policy/PrivacyPolicy/Index.jsx";
import Location from "./User/Pages/Policy/Location/Index.jsx";
import Faq from "./User/Pages/Policy/FAQ's/Faq";
import Help from "./User/Pages/Policy/Help/Help";
import Clients from "./User/Pages/Policy/Clients/Clients";

import SP_Clients from "./ServiceProvider/Pages/Policys/Clients/index.jsx";
import SP_FAQs from "./ServiceProvider/Pages/Policys/FAQs/index.jsx";
import SP_Help from "./ServiceProvider/Pages/Policys/Help/index.jsx";
import SP_Loaction from "./ServiceProvider/Pages/Policys/Locations/Index.jsx";
import SP_Privacy from "./ServiceProvider/Pages/Policys/Privacy Policy/Index.jsx";
import SP_Services from "./ServiceProvider/Pages/Policys/Services/Index.jsx";
import SP_Support from "./ServiceProvider/Pages/Policys/Support/Index.jsx";
import SP_Trems from "./ServiceProvider/Pages/Policys/Terms of Use/Index.jsx";
import Favorites from "./User/Pages/Profile/Favorites/Favorites.jsx";
import HowitWorks from "./Common/pages/How_it_Work";
import axios from "./Common/Api";
import { toast } from "react-hot-toast";
import { GlobalContext } from "./GlobalContext.jsx";
import Test from "./User/test/index.jsx";
import Subcategory from "./User/Pages/Subcategory/Subcategory.jsx";
import Messages2 from "./ServiceProvider/Pages/SP_ChatScreen/Messages2.jsx";
import Messages from "./User/Pages/Chatscreen/Messages.jsx";
import Payment from "./User/Pages/payment/index.jsx";

const AllRoutes = () => {
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      localStorage.setItem("lat", position.coords.latitude);
      localStorage.setItem("lon", position.coords.longitude);
    });
  }, []);
  const { category, setcategory, position, setPosition } =
    useContext(GlobalContext);

  const Lat = JSON.parse(localStorage.getItem("lat"));
  const Long = JSON.parse(localStorage.getItem("long"));

  // ! get Category api call
  const getcategory = async () => {
    try {
      const response = await axios.post(
        "get-category",
        { latitude: Lat, longitude: Long },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setcategory(response.data.data);
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    getcategory();
  }, []);
  //! get Category api function End

  // useEffect(() => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(function (position) {
  //       setPosition({
  //         latitude: position.coords.latitude,
  //         longitude: position.coords.longitude,
  //       });
  //     });
  //   } else {
  //     console.log("Geolocation is not available in your browser.");
  //   }
  // }, []);

  return (
    <Routes>
      <Route path="/" element={<Landingpage />} />
      <Route path="/Login" element={<Login />} />
      <Route path="/Register" element={<Register />} />
      <Route path="/ForgotPassword" element={<ForgotPassword />} />
      <Route path="/Personalinformation" element={<Personalinformation />} />
      <Route path="/Professional" element={<Professional />} />
      <Route path="/Search" element={<Search />} />
      <Route path="/BookProject" element={<BookProject />} />
      <Route path="/DealsNearYou" element={<DealsNearYou />} />
      <Route path="/WorkerProfile" element={<WorkerProfile />} />
      <Route path="/DealDetails" element={<Dealsdeatail />} />
      <Route path="/Successfully" element={<Successfully />} />
      <Route path="/BookingFailed" element={<Paymnetfaild />} />
      <Route path="/Profilesidebar" element={<Profilesidebar />} />
      <Route path="/ProfileSdebar" element={<ProfileSdebar />} />
      <Route path="/ContactUs" element={<Contactus />} />
      <Route path="/AboutUs" element={<Aboutus />} />
      <Route path="/Services" element={<Services />} />
      <Route path="/Terms" element={<Terms />} />
      <Route path="/Support" element={<Support />} />
      <Route path="/PrivacyPolicy" element={<Privacy />} />
      <Route path="/Loaction" element={<Location />} />
      <Route path="/Clients" element={<Clients />} />
      <Route path="/Faq" element={<Faq />} />
      <Route path="/Help" element={<Help />} />
      <Route path="/HowitWorks" element={<HowitWorks />} />
      <Route path="/Subcategory" element={<Subcategory />} />
      <Route path="/PaymentVerify" element={<Payment />} />

      <Route path="/Profile" element={<Profile />}>
        <Route
          path="/Profile/"
          element={<Navigate replace to="/Profile/EditProfile" />}
        />
        <Route path="/Profile/EditProfile" element={<EditProfile />} />
        <Route path="/Profile/PaymentOptions" element={<PaymentOptions />} />
        <Route
          path="/Profile/MyBookingsUpcoming"
          element={<MyBookingsUpcoming />}
        />
        <Route path="/Profile/Favorites" element={<Favorites />} />
        <Route path="/Profile/Settings" element={<Settings />} />
        <Route path="/Profile/Chatscreen" element={<Chatscreen />} />

        <Route path="/Profile/Messages" element={<Messages />} />
        <Route path="/Profile/Notification" element={<Notification />} />
      </Route>

      {/* Service Provider Flow */}
      <Route path="/SP_Home" element={<SP_Landingpage />} />
      <Route path="/SP_Login" element={<SP_login />} />
      <Route path="/SP_Register" element={<SP_Register />} />
      <Route path="/SP_Seccessfully" element={<Successfullycreated />} />
      <Route path="/SP_Dashboard" element={<Dashboard />} />
      <Route path="/SP_Aboutus" element={<SP_Aboutus />} />
      <Route path="/SP_Contactus" element={<SP_Contactus />} />
      <Route path="/SP_Clients" element={<SP_Clients />} />
      <Route path="/SP_FAQs" element={<SP_FAQs />} />
      <Route path="/SP_Help" element={<SP_Help />} />
      <Route path="/SP_Loaction" element={<SP_Loaction />} />
      <Route path="/SP_PrivacyPolicy" element={<SP_Privacy />} />
      <Route path="/SP_Services" element={<SP_Services />} />
      <Route path="/SP_Support" element={<SP_Support />} />
      <Route path="/SP_Terms" element={<SP_Trems />} />
      <Route path="/HW_test" element={<Test />} />

      <Route path="/SP_Profile" element={<Profile2 />}>
        <Route
          path="/SP_Profile/"
          element={<Navigate replace to="/SP_Profile/SP_EditProfile" />}
        />
        <Route path="/SP_Profile/SP_EditProfile" element={<EditProfile2 />} />
        <Route path="/SP_Profile/MyWallet" element={<MyWallet />} />
        <Route path="/SP_Profile/SP_ChatScreen" element={<Chatscreen2 />} />
        <Route path="/SP_Profile/Messages" element={<Messages2 />} />

        <Route path="/SP_Profile/SP_Settings" element={<Settings2 />} />
        <Route path="/SP_Profile/Notification" element={<SP_Notification />} />
      </Route>
    </Routes>
  );
};

export default AllRoutes;
