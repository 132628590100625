import React from "react";
import "./payment.css";
import Navbar2 from "../UI/Nvabar2";
import Footer from "../UI/Footer";
import cross from "../../assets/svg/Paymnetfaild.svg";
import { useNavigate } from "react-router-dom";
const Paymnetfaild = () => {
  const navigate = useNavigate();
  const handleclick = () => {
    navigate("/");
  };
  return (
    <>
      {/* <Navbar2 /> */}
      <div className="main_div">
        <div className="sub_main_div">
          <div className="_img__box">
            <img src={cross} alt="" />
          </div>
          <div className="text">
            <h2>Booking Failed!</h2>
            <p>
              Your last booking has been cancelled because your booking time has
              been expired
            </p>
          </div>
          <button className="sbtn_1" onClick={() => handleclick()}>
            Ok
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Paymnetfaild;
