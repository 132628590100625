import * as Yup from "yup";
export const LoginSchemas = Yup.object({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format"
    )
    .required("Please enter your email"),
  password: Yup.string().min(4).required("Please enter your password"),
});

export const RegisterSchemas = Yup.object({
  FullName: Yup.string()
    .matches(
      /^[a-zA-Z\s]+$/,
      "Full name should only contain letters and spaces"
    )
    .min(2, "Full name must be at least 2 characters long")
    .max(25, "Full name must be less than 25 characters long")
    .required("Please enter your full name"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format"
    )
    .required("Please enter your email"),
  password: Yup.string().min(4).required("Please enter your password"),
  MobileNumber: Yup.string()
    .matches(/^\d{3,10}$/, "Please enter a valid mobile number")
    .required("Please enter your mobile number"),
});

export const ForgotPasswordSchemas = Yup.object({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format"
    )
    .required("Please enter your email"),
});

export const PersonalSchemas = Yup.object({
  FullName: Yup.string()
    .matches(
      /^[a-zA-Z\s]+$/,
      "Full name should only contain letters and spaces"
    )
    .min(2, "Full name must be at least 2 characters long")
    .max(25, "Full name must be less than 25 characters long")
    .required("Please enter your full name"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format"
    )
    .required("Please enter your email"),
  password: Yup.string().min(4).required("Please enter your password"),
  MobileNumber: Yup.string()
    .matches(/^\d{3,10}$/, "Please enter a valid mobile number")
    .required("Please enter your mobile number"),
  ConfirmPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

export const ProfessionalSchemas = Yup.object({
  RatePerhour: Yup.string().required("Required field can't be empty"),
  Writehere: Yup.string().required("Required field can't be empty"),
  ResolutionExperience: Yup.string()
    .min(5, "Please add your experience in details")
    .required("Required field can't be empty"),
  Skills: Yup.string().required("Please add at least one skill"),
  SelectedCatId: Yup.string().required("Please select category"),
  SelectedSubcatId: Yup.string().required("Please select subcategory"),
});

export const AddressSchemas = Yup.object({
  YourName: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .max(25, "Please enter a valid name for your account")
    .required("Please enter your full name"),

  Mobile: Yup.string()
    .matches(/^\d{3,10}$/, "Please enter a valid mobile number")
    .required("Please enter your mobile number "),

  City: Yup.string()
    .min(2, "City name must be at least 2 characters")
    .max(20, "Please enter a valid city name")
    .required("Required field can't be empty"),

  Address: Yup.string()
    .min(10, "Address must be at least 10 characters")
    .max(50, "Please enter a valid address ")
    .required("Required field can't be empty"),

  WorkRequirement: Yup.string()
    .min(3, "Work requirement must be at least 3 characters")
    .max(200, "Work requirement cannot exceed 100 characters")
    .required("Please provide details about the work requirement"),
});

export const ModeoneSchemas = Yup.object({
  FullName: Yup.string().min(2).max(25).required("Please enter your full name"),
  LastName: Yup.string().min(2).max(25).required("Please enter your last name"),
  Address: Yup.string().min(2).max(25).required("Please enter your address"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
});

export const ModeltwoSchemas = Yup.object({
  Number: Yup.string()
    .min(2, "Please enter a valid phone number")
    .max(10, "Phone number must not exceed 10 digits")
    .required("Please enter your phone number"),
});

export const EditProfileSchemas = Yup.object({
  FullName: Yup.string().min(2).max(25).required("Please enter your full name"),
  email: Yup.string().email().required("Please enter your email"),
  MobileNumber: Yup.string()
    .matches(/^\d{3,10}$/, "Please enter a valid mobile number")
    .required("Please enter your mobile number"),
});

export const ChangePasswordSchemas = Yup.object({
  CurrentPassword: Yup.string()
    .min(4, "Password must be at least 4 characters")
    .required("Please enter your current password"),
  NewPassword: Yup.string()
    .min(4, "Password must be at least 4 characters")
    .required("Please enter your new password"),
  ConfirmNewPassword: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("NewPassword"), null], "Passwords must match"),
});

export const ContactusSchemas = Yup.object({
  FullName: Yup.string().min(2).max(25).required("Please enter your full name"),
  Email: Yup.string().email().required("Please enter your email"),
  MobileNumber: Yup.number().required("Please enter your mobile number"),
  Message: Yup.string().min(2).required("Please enter your message"),
});

export const EditProfile2Schemas = Yup.object({
  FullName: Yup.string().min(2).max(25).required("Please enter your full name"),
  email: Yup.string().email().required("Please enter your email"),
  MobileNumber: Yup.string()
    .min(10, "Rate per hour must be at least 10")
    .max(1000000, "Rate per hour can't exceed 100000")
    .required("Required field can't be empty"),
  RatePerhour: Yup.string().required("Required field can't be empty"),
  Writehere: Yup.string().required("Required field can't be empty"),
  ResolutionExperience: Yup.string().required("Required field can't be empty"),
  SelectedCatId: Yup.string().required("Please select category"),
  SelectedSubcatId: Yup.string().required("Please select subcategory"),
});

export const SubscribeSchemas = Yup.object({
  Subscription: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format"
    )
    .required("Please enter your email"),
});
