import React, { useState, useEffect, useContext } from "react";
import "../EditProfile2/EditProfile2.css";
import { useFormik } from "formik";
import { EditProfile2Schemas } from "../../../../schemas/index";
import Profile from "../../../../User/assets/svg/Profile.svg";
import camera from "../../../../User/assets/svg/camera.svg";
import axios from "../../../../Common/Api/index";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../GlobalContext";
import close from "../../../../User/assets/svg/Close.svg";
import { Backdrop, CircularProgress } from "@mui/material";
import { db } from "../../../../Common/Firebase/firebase";
import { doc, collection, setDoc } from "firebase/firestore";

const initialValues = {
  FullName: "",
  MobileNumber: "",
  email: "",
  RatePerhour: "",
  Writehere: "",
  YourSpeciality: "",
  ResolutionExperience: "",
  Skills: "",
  SelectedCatId: "",
  SelectedSubcatId: "",
};

const EditProfile2 = () => {
  const userdata = JSON.parse(localStorage.getItem("login"));
  const [loading, setloading] = useState(false);
  const {
    profiledata,
    setprofiledata,
    category,
    profilereload,
    setProfilereload,
    profileDatas,
    setpofileDatas,
  } = useContext(GlobalContext);
  // console.log("🚀 ~ EditProfile2 ~ profiledata:", profiledata);
  const [skills, setSkills] = useState();
  const [newSkill, setNewSkill] = useState("");
  const [imageCount, setImageCount] = useState(0);
  const [Image, setImage] = useState({ img: "" });
  const [profimg, setprofimg] = useState();
  const [specialist, setSpecialist] = useState([]);
  const [imagesToSend, setImagesToSend] = useState([]);
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  // console.log("🚀 ~ EditProfile2 ~ images:", images);
  const [selectedCatId, setSelectedCatId] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcatId, setSelectedSubcatId] = useState("");
  // console.log("🚀 ~ EditProfile2 ~ selectedSubcatId:====>", selectedSubcatId);

  // const handleImageChange = (event) => {
  //   // console.log("run");
  //   const WorkerImg = {
  //     worker_img: URL.createObjectURL(event.target.files[0]),
  //   };
  //   let url = URL.createObjectURL(event.target.files[0]);

  //   setImages(WorkerImg);
  //   const selectedImages = Array.from(event.target.files);
  //   const fileArray = selectedImages.map((file) => {
  //     return new File([file], file.name);
  //   });
  //   setImagesToSend([...imagesToSend, ...fileArray]);
  //   if (images.length + selectedImages.length > 5) {
  //     setImages([
  //       ...images,
  //       ...selectedImages.map((file) => ({
  //         worker_img: URL.createObjectURL(file),
  //       })),
  //     ]);
  //     setImageCount(imageCount + selectedImages.length);
  //   } else {
  //     toast.success("You can only select a maximum of 5 images", {
  //       position: "top-right",
  //       theme: "colored",
  //     });
  //   }
  // };

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    const totalImages = images.length + selectedImages.length;

    if (totalImages > 5) {
      // Show a toast and limit the images to 5
      toast.error("You can only select a maximum of 5 images", {
        position: "top-right",
        theme: "colored",
      });

      // Only allow 5 images to be added
      const limitedImages = selectedImages.slice(0, 5 - images.length);
      const fileArray = limitedImages.map(
        (file) => new File([file], file.name)
      );

      setImagesToSend([...imagesToSend, ...fileArray]);
      setImages([
        ...images,
        ...limitedImages.map((file) => ({
          worker_img: URL.createObjectURL(file),
          file, // Store the file along with the image URL
        })),
      ]);
    } else {
      // Add selected images as it is, since it's within the limit
      const fileArray = selectedImages.map(
        (file) => new File([file], file.name)
      );

      setImagesToSend([...imagesToSend, ...fileArray]);
      setImages([
        ...images,
        ...selectedImages.map((file) => ({
          worker_img: URL.createObjectURL(file),
          file, // Store the file along with the image URL
        })),
      ]);
    }
  };

  // const checkimageid = images.filter((img) => img.id);
  // console.log("🚀 ~ EditProfile2 ~ checkimageid:", checkimageid);

  const deleteImages = async (id) => {
    try {
      const response = await axios.post(
        "delete-image",
        {
          id: id,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${userdata.data.data.api_token}`,
          },
        }
      );
      // console.log("shown response of delete image", response);
    } catch (error) {
      // console.log("check error image delete", error);
    }
  };
  const handimgleDelete = (index, id) => {
    if (id) {
      deleteImages(id);
    }

    // Remove the image from both `images` and `imagesToSend`
    const updatedImages = images.filter((image, i) => i !== index);
    const updatedImagesToSend = imagesToSend.filter((file, i) => i !== index);
    setImages(updatedImages);
    setImagesToSend(updatedImagesToSend);
    setImageCount(imageCount - 1);
  };

  const handleSkillChange = (event) => {
    setNewSkill(event.target.value);
  };

  const handleAddSkill = () => {
    if (newSkill.trim() !== "") {
      setSkills([...skills, newSkill]);
      setNewSkill("");
      setFieldValue("Skills", [...skills, newSkill]);
    }
  };

  const handleCancelSkill = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    setSkills(updatedSkills);
    if (updatedSkills.length === 0) {
      setFieldValue("Skills", "");
    }
  };
  const removeSkillIconSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#1E2D38">
      <g clip-path="url(#clip0_27_6463)">
        <path
          d="M10.0001 8.82129L14.1251 4.69629L15.3034 5.87462L11.1784 9.99962L15.3034 14.1246L14.1251 15.303L10.0001 11.178L5.87511 15.303L4.69678 14.1246L8.82178 9.99962L4.69678 5.87462L5.87511 4.69629L10.0001 8.82129Z"
          fill="#1E2D38"
        />
      </g>
      <defs>
        <clipPath id="clip0_27_6463">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const addIconSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#1E2D38">
      <path d="M10 2V10H18V12H10V20H8V12H0V10H8V2H10Z" />
    </svg>
  );

  const workerImg = async () => {
    const formdata = new FormData();
    imagesToSend.forEach((image) => {
      formdata.append("worker_img[]", image);
    });
    try {
      let config = {
        url: "add-image",
        method: "post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userdata.data.data.api_token}`,
        },
      };
      axios(config)
        .then((response) => {})
        .catch((error) => {});
    } catch (error) {
      if (error.response.data?.status == "failed") {
      }
    }
  };

  const EditProfile = async () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("name", values.FullName);
    formdata.append("phone", values.MobileNumber);
    formdata.append("ratePerhour", values.RatePerhour);
    formdata.append("description", values.Writehere);
    formdata.append("skil", skills);
    formdata.append("cat_id", selectedCatId);
    formdata.append("subcate_id", selectedSubcatId);
    formdata.append("experience", values.ResolutionExperience);
    formdata.append("profile_image", profimg);
    try {
      let config = {
        url: "update-profile",
        method: "post",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
        },
      };
      axios(config)
        .then((response) => {
          setloading(false);

          if (response.data?.status == "failed") {
            // console.log("check update-profile failed response=====>", response);
          } else if (response.data.status == "success") {
            // console.log("check update-profile response=====>", response);
            GetSpeciality();
            getProfile();
            setpofileDatas(response.data.data);
            setProfilereload(true);
            toast.success(response.data?.message, {
              position: "top-right",
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          setloading(false);
        });
    } catch (error) {
      setloading(false);
    }
  };
  // console.log("🚀 ~ EditProfile ~ selectedCatId:", selectedCatId);

  const GetSpeciality = async () => {
    // setloading(true);
    try {
      const response = await axios.get("get-category", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setloading(false);
      // console.log(" get-category response......=>", response);
      setSpecialist(response.data.data);
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    GetSpeciality();
  }, []);
  const onSubmit = () => {
    workerImg();
    EditProfile();
  };
  const pathname = useLocation();
  const getProfile = async () => {
    try {
      const response = await axios.get("get-profile", {
        headers: {
          Authorization: `Bearer ${userdata?.data?.data?.api_token}`,
        },
      });
      // console.log("🚀 ~ getProfile ~ response:", response);

      setprofiledata(response.data.data);
      // Prepare data to store in Firestore
      const data = {
        userrole: response.data.data.user_type,
        name: response.data.data.name ?? "",
        email: response.data.data.email,
        id: response.data.data.id,
        image: response.data.data.profile_image
          ? response.data.data.profile_image
          : Profile,
        fcmtoken: response?.data?.data?.devicetoken,
        updatedat: new Date(),
      };

      // Store user data in Firestore with error handling
      const doref = doc(
        collection(db, "users"),
        JSON.stringify(response.data.data.id)
      );
      await setDoc(doref, data)
        .then((rrr) => {
          console.log("User data successfully stored in Firestore:", rrr);
        })
        .catch((error) => {
          console.error("Error setting data in Firestore:", error);
        });
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.removeItem("login");
        localStorage.removeItem("lat");
        localStorage.removeItem("lon");
        localStorage.removeItem("lon");
        localStorage.removeItem("long");
        localStorage.removeItem("messageSenderdata");
        navigate("/");
      }
    }
  };
  useEffect(() => {
    getProfile();
  }, []);
  useEffect(() => {
    if (profilereload === true) {
      getProfile();
      setProfilereload(false);
    }
  }, [profilereload]);

  useEffect(() => {
    setFieldValue("FullName", profileDatas?.name);
    setFieldValue("email", profileDatas?.email);
    setFieldValue("MobileNumber", profileDatas?.phone);
    setFieldValue("RatePerhour", profiledata?.ratePerhour);
    setFieldValue("Writehere", profiledata?.description);
    setFieldValue("ResolutionExperience", profiledata?.experience);
    setFieldValue("SelectedCatId", profiledata?.CatId);
    setFieldValue("SelectedSubcatId", profiledata?.SubcateId);
    setSelectedCatId(profiledata?.CatId);
    setSelectedSubcatId(profiledata?.SubcateId);

    const selectedCategory = specialist.find(
      (cat) => cat.CatId === profiledata?.CatId
    );
    setSubcategories(selectedCategory?.Subcategory);

    setSkills(profiledata?.skils || []);
    setImages(profiledata?.worker_images);
    setImage({ img: profiledata?.profile_image });
  }, [profiledata, specialist]);
  console.log("🚀 ~ useEffect ~ profiledata:", profiledata);
  //  ! get orfile api calling this function
  //! get profile api function End
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    setFieldValue,
    handleSubmit,
    errors,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: EditProfile2Schemas,
    onSubmit,
  });

  const handleCategoryChange = (e) => {
    const catId = e.target.value;
    setSelectedCatId(catId);
    setFieldValue("SelectedCatId", catId);

    const selectedCategory = specialist.find(
      (cat) => cat.CatId.toString() === catId
    );
    if (selectedCategory) {
      setSubcategories(selectedCategory.Subcategory);
    } else {
      setSubcategories([]);
    }
  };
  const handleSubcategoryChange = (e) => {
    // Step 2
    setSelectedSubcatId(e.target.value);
    setFieldValue("SelectedSubcatId", e.target.value);
  };

  return (
    <>
      <div className="All-Conatinor-perfect-divv">
        <div className="All-Containor-perfect-second-divv">
          <form onSubmit={handleSubmit} className="editprofile2-main3-div">
            <div className="edit-profile-main-div">
              <div className="edit-profile-double-div">
                <span className="Settikgs-main-span">Edit Profile</span>
              </div>
              <div className="edit-profile-main27">
                <div className="Ht-ml-for-imput mb-4">
                  <label className="" htmlFor="mal">
                    <img
                      src={Image.img ? Image.img : Profile}
                      alt=""
                      className="pic-on-add"
                    />
                    <div className="SandIcon-addeting-div">
                      <img className="Cemara-te-img" src={camera} alt="" />
                    </div>
                  </label>

                  <input
                    id="mal"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      setImage({ img: URL.createObjectURL(e.target.files[0]) });
                      setprofimg(e.target.files[0]);
                    }}
                    className="Malte-pal-cls"
                  />

                  <img
                    src={Image.img ? Image.img : Profile}
                    width="100px"
                    height="100px"
                    className="add-kiya-muja"
                  />
                </div>
                <div className="input-text-div-dad-div">
                  <div className="input-text-div-pap-div">
                    <div className="label-or-feild-div24">
                      <label htmlFor="fullname" className="html12-label-span">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="kathryn-input22"
                        placeholder="Kathryn Murphy"
                        name="FullName"
                        value={values.FullName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.FullName && touched.FullName ? (
                        <p className="mastu-to-eroor mt-0">
                          {errors.FullName}{" "}
                        </p>
                      ) : null}
                    </div>
                    <div className="label-or-feild-div24">
                      <label htmlFor="fullname" className="html12-label-span">
                        Mobile Number
                      </label>
                      <input
                        type="number"
                        className="kathryn-input22"
                        placeholder="+1 989809 98980"
                        name="MobileNumber"
                        value={values.MobileNumber}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            handleChange(e);
                          }
                        }}
                        onBlur={handleBlur}
                        maxLength="10"
                      />
                      {errors.MobileNumber && touched.MobileNumber ? (
                        <p className="mastu-to-eroor mt-0">
                          {errors.MobileNumber}{" "}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="label-or-feild-div24-90">
                    <label htmlFor="fullname" className="html12-label-span-75">
                      Email
                    </label>

                    <input
                      type="email"
                      className="kathryn-sec-input"
                      placeholder="kathryn_murphy07@gmail.com"
                      value={values.email}
                      style={{ color: "#000" }}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={true}
                    />
                    {errors.email && touched.email ? (
                      <p className="mastu-to-eroor mt-0">{errors.email} </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="Professional-Info-main-div-span">
              <span className="Professional-Info">Professional Info</span>
            </div>
            <div className="Professional-Info-main-div">
              <div className="Professional-Info-child-div">
                <div className="rate-per-hour-dv">
                  <span className="all-input-span-font">Rate (Per hour)</span>
                  <div className="doler-hai-bhaiii mt-2">
                    <span className="doler-span">₦</span>
                    <input
                      className="dolar-bot-input"
                      placeholder=""
                      type="Number"
                      value={values.RatePerhour}
                      // onChange={handleChange}
                      onBlur={handleBlur}
                      name="RatePerhour"
                      min="10"
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        const value = e.target.value;

                        // Allow input if it matches regex or is empty (allowing deletion)
                        if (
                          value === "" ||
                          (re.test(value) && parseInt(value) <= 1000000)
                        ) {
                          handleChange(e);
                        }
                      }}
                      maxLength="7"
                    />
                  </div>
                  {errors.RatePerhour && touched.RatePerhour ? (
                    <p className="mastu-to-eroor mt-0">{errors.RatePerhour} </p>
                  ) : null}
                </div>

                <span className="all-input-span-font mt-3">Skills</span>
                <div className="doler-hai-bhaiii mt-2">
                  <div className="Lorem-btn">
                    <div className="Lorem-btn">
                      <div className="add-skill-input">
                        <input
                          className="dolar-bot-input"
                          type="text"
                          placeholder="Add new skill"
                          name="Skills"
                          value={newSkill}
                          onChange={handleSkillChange}
                          onBlur={handleBlur}
                          maxLength={25}
                        />
                        <button
                          className="add-skill-btn"
                          onClick={handleAddSkill}
                          dangerouslySetInnerHTML={{ __html: addIconSvg }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Skills-main-divv-yya mt-2">
                  {skills?.map((skill, index) => (
                    <div key={index} className="skill-item">
                      <span className="skill-text">{skill}</span>
                      <img
                        className="remove-skill-icon"
                        src={close}
                        onClick={() => handleCancelSkill(index)}
                      />
                    </div>
                  ))}
                </div>
                {errors.Skills && touched.Skills ? (
                  <p className="mastu-to-eroor mt-0">{errors.Skills} </p>
                ) : null}
                <span className="all-input-span-font mt-3">
                  Your Speciality
                </span>

                <div className="selact-ka-liya-jugad mt-2">
                  <select
                    className="selact-tag-one"
                    name="SelectedCatId"
                    value={selectedCatId}
                    onChange={handleCategoryChange}
                    placeholder="Select Service">
                    <option value="" disabled selected hidden>
                      Select Service
                    </option>
                    {specialist?.map((item, index) => {
                      return (
                        <option key={index} value={item.CatId}>
                          {item.CatName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors.SelectedCatId && touched.SelectedCatId ? (
                  <p className="mastu-to-eroor mt-0">{errors.SelectedCatId} </p>
                ) : null}
                <div className="selact-ka-liya-jugad mt-2">
                  <select
                    className="selact-tag-one"
                    name="SelectedSubcatId"
                    value={selectedSubcatId}
                    onChange={handleSubcategoryChange}
                    placeholder="Select Service">
                    <option value="" disabled selected hidden>
                      Select Sub Service
                    </option>
                    {subcategories?.map((subcat) => (
                      <option key={subcat.SubcateId} value={subcat.SubcateId}>
                        {subcat.SubcateName}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.SelectedSubcatId && touched.SelectedSubcatId ? (
                  <p className="mastu-to-eroor mt-0">
                    {errors.SelectedSubcatId}{" "}
                  </p>
                ) : null}
              </div>
              <div className="text-area-main-div mt-3">
                <div className="text-area-child-div">
                  <label htmlFor="text-area" className="Rate-label">
                    About You
                  </label>
                  <textarea
                    className="text-area50-text"
                    placeholder="Write here"
                    style={{ fontFamily: "inherit" }}
                    name="Writehere"
                    value={values.Writehere}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    cols="40"
                    rows="5"></textarea>
                  {errors.Writehere && touched.Writehere ? (
                    <p className="mastu-to-eroor">{errors.Writehere} </p>
                  ) : null}
                </div>
                <div className="text-area-child-div">
                  <label htmlFor="text-area" className="Rate-label">
                    Your Experience
                  </label>
                  <textarea
                    placeholder="Write here"
                    className="text-area50-text"
                    style={{ fontFamily: "inherit" }}
                    name="ResolutionExperience"
                    value={values.ResolutionExperience}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    cols="40"
                    rows="5"></textarea>
                  {errors.ResolutionExperience &&
                  touched.ResolutionExperience ? (
                    <p className="mastu-to-eroor ">
                      {errors.ResolutionExperience}{" "}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <span className="mt-3">Upload Work Photos</span>
            <div className="add-delet-option">
              <label className="Ht-ml-for" htmlFor={"mal1"}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 9V5H11V9H15V11H11V15H9V11H5V9H9ZM10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18Z"
                    fill="#00AD5A"
                  />
                </svg>
              </label>
              <input
                id="mal1"
                type="file"
                accept="image/*"
                multiple
                onChange={handleImageChange}
                className="Malte-pal-cls"
              />
              <div className="Not-overflowing">
                <div className="overflowing-add-immgg">
                  {images?.slice(0, 5)?.map((image, index) => {
                    return (
                      <div className="sab-img-ka-div">
                        <img
                          src={image?.worker_img}
                          alt={`Image- ${index}`}
                          className="add-kiya-huva-pic"
                          // style={{width: "79.71px", height: "79.71px"}}
                        />
                        <div
                          className="Btn-dada-delet"
                          onClick={() => handimgleDelete(index, image.id)}>
                          <svg
                            className="Ma-svg-top"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_25_3172)">
                              <path
                                d="M7.0014 6.17516L9.8889 3.28766L10.7137 4.11249L7.82623 6.99999L10.7137 9.88749L9.8889 10.7123L7.0014 7.82483L4.1139 10.7123L3.28906 9.88749L6.17656 6.99999L3.28906 4.11249L4.1139 3.28766L7.0014 6.17516Z"
                                fill="#211B24"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_25_3172">
                                <rect width="14" height="14" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="button-save-div">
              <button type="submit" className="save-button-ppx">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default EditProfile2;
